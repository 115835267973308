import axios from "axios";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { BASE_URL } from "../config";
import Loader from "./Loader";
import styles from "../styles/_components/demolishModal.module.scss";

const style = {
  height: "100vh",
  width: "100%",
  position: "fixed",
  left: "0px",
  top: "0px",
  opacity: 0.45,
  background: "#000000 0% 0% no-repeat padding-box",
};

const Backdrop = ({ setIsOpen }) => (
  <div style={style} onClick={() => setIsOpen(false)}></div>
);

const DemolishModal = ({ setIsOpen }) => {
  let token = localStorage.getItem("authToken");

  let user_id = localStorage.getItem("user_id");

  const [description, setDescription] = useState("");

  const [images, setImages] = useState([]);

  const [licenceNum, setLicenceNum] = useState("");

  const [manufacturer, setManufacturer] = useState("");

  const [model, setModel] = useState("");

  const [yop, setYop] = useState("");

  const [hp, setHp] = useState("");

  const [loading, setLoading] = useState(false);

  const [success, setSuccess] = useState(false);

  const [licenseData, setLicenseData] = useState(null);

  const [disableAll, setDisableAll] = useState(false);

  let vehicleDetailFields = {
    mispar_rechev: "מספר רכב",
    tozeret_cd: "קוד יצרן",
    sug_degem: "סוג דגם",
    tozeret_nm: "יצרן",
    degem_cd: "קוד דגם",
    degem_nm: "שם דגם",
    ramat_gimur: "רמת גימור",
    ramat_eivzur_betihuty: "רמת אבזור בטיחותי",
    kvutzat_zihum: "קבוצת זיהום",
    shnat_yitzur: "שנת יצור",
    degem_manoa: "דגם מנוע",
    mivchan_acharon_dt: "טסט אחרון",
    mispar_dlatot: "מספר דלתות",
    tokef_dt: "תוקף רישיון רכב",
    baalut: "בעלות",
    misgeret: "מספר שלדה",
    tzeva_cd: "קוד צבע",
    tzeva_rechev: "צבע רכב",
    mida_zmig_kidmi: "צמיג קדמי",
    zmig_kidmi: "צמיג קדמי",
    zmig_ahori: "צמיג אחורי",
    sug_delek_nm: "סוג דלק",
    horaat_rishum: "הוראת רישום",
    moed_aliya_lakvish: "מועד עלייה לכביש",
    kinuy_mishari: "דגם",
    nefah_manoa: "נפח מנוע",
    nefach_manoa: "נפח מנוע",
    hanaa_nm: "הנעה",
    delek_nm: "דלק",
    mazgan_ind: "מזגן",
    technologiat_hanaa_nm: "טכנולוגית הנעה",
    kosher_grira_bli_blamim: "כושר גלילה בלי בלמים",
    kvuzat_agra_cd: "קבוצת אגרה",
    merkav: "מרכב",
    automatic_ind: "תיבת הילוכים",
    mispar_moshavim: "מספר מושבים",
    mishkal_kolel: "משקל",
    koah_sus: "כוס סוס",
    abs_ind: "ABS",
    kosher_grira_im_blamim: "כושר גרירה עם בלמים",
  };

  // fetch license api data
  // example license no => 75348102, 6618355
  // useEffect(() => {
  //   if (licenceNum) {
  //     const fetchData = async () => {
  //       if (licenceNum.length >= 7) {
  //         setLoading(true);
  //         try {
  //           const payload = {
  //             mispar_rechev: licenceNum,
  //           };
  //           const res = await axios.get(
  //             `https://data.gov.il/api/3/action/datastore_search?resource_id=053cea08-09bc-40ec-8f7a-156f0677aff3&filters=${JSON.stringify(
  //               payload
  //             )}`
  //           );

  //           if (res.data.result.records.length > 0) {
  //             let modifiedArr = Object.entries(res.data.result.records[0]);
  //             modifiedArr[12] = [
  //               "mivchan_acharon_dt",
  //               format(new Date(modifiedArr[12][1]), "dd.MM.yy"),
  //             ];
  //             modifiedArr[13] = [
  //               "tokef_dt",
  //               format(new Date(modifiedArr[13][1]), "dd.MM.yy"),
  //             ];

  //             const newArr = modifiedArr.map((i, idx) => {
  //               if (i[0] === "mispar_rechev") {
  //                 return ["מספר רכב", i[1]];
  //               } else if (i[0] === "tozeret_cd") {
  //                 return ["קוד יצרן", i[1]];
  //               } else if (i[0] === "sug_degem") {
  //                 return ["סוג דגם", i[1]];
  //               } else if (i[0] === "tozeret_nm") {
  //                 return ["יצרן", i[1]];
  //               } else if (i[0] === "degem_cd") {
  //                 return ["קוד דגם", i[1]];
  //               } else if (i[0] === "degem_nm") {
  //                 return ["שם דגם", i[1]];
  //               } else if (i[0] === "ramat_gimur") {
  //                 return ["רמת גימור", i[1]];
  //               } else if (i[0] === "ramat_eivzur_betihuty") {
  //                 return ["רמת אבזור בטיחותי", i[1]];
  //               } else if (i[0] === "kvutzat_zihum") {
  //                 return ["קבוצת זיהום", i[1]];
  //               } else if (i[0] === "shnat_yitzur") {
  //                 return ["שנת יצור", i[1]];
  //               } else if (i[0] === "degem_manoa") {
  //                 return ["דגם מנוע", i[1]];
  //               } else if (i[0] === "mivchan_acharon_dt") {
  //                 return ["תאריך ביצוע טסט", i[1]];
  //               } else if (i[0] === "tokef_dt") {
  //                 return ["רישיון בתוקף עד", i[1]];
  //               } else if (i[0] === "baalut") {
  //                 return ["בעלות", i[1]];
  //               } else if (i[0] === "misgeret") {
  //                 return ["מספר שלדה", i[1]];
  //               } else if (i[0] === "tzeva_cd") {
  //                 return ["קוד צבע", i[1]];
  //               } else if (i[0] === "tzeva_rechev") {
  //                 return ["צבע רכב", i[1]];
  //               } else if (i[0] === "zmig_kidmi") {
  //                 return ["צמיג קדמי", i[1]];
  //               } else if (i[0] === "zmig_ahori") {
  //                 return ["צמיג אחורי", i[1]];
  //               } else if (i[0] === "sug_delek_nm") {
  //                 return ["סוג דלק", i[1]];
  //               } else if (i[0] === "horaat_rishum") {
  //                 return ["הוראת רישום", i[1]];
  //               } else if (i[0] === "moed_aliya_lakvish") {
  //                 return ["מועד עלייה לכביש", i[1]];
  //               } else if (i[0] === "kinuy_mishari") {
  //                 return ["דגם", i[1]];
  //               } else {
  //                 return i;
  //               }
  //             });

  //             for (let index = 0; index < newArr.length; index++) {
  //               const element = newArr[index];

  //               if (element[0] === "_id") {
  //                 newArr.splice(index, 1);
  //               }
  //             }

  //             setLicenseData(newArr);

  //             setDisableAll(true);

  //             setSuccess(true);

  //             setManufacturer(res.data.result.records[0].tozeret_nm);

  //             setModel(res.data.result.records[0].kinuy_mishari);

  //             setYop(res.data.result.records[0].shnat_yitzur);
  //           } else {
  //             setLoading(false);
  //             setSuccess(false);
  //             setLicenseData(null);
  //             setDisableAll(false);
  //           }

  //           if (res.status === 200) {
  //             const payload_2 = {
  //               degem_nm: res.data.result.records[0].degem_nm,
  //             };
  //             try {
  //               const res_2 = await axios.get(
  //                 `https://data.gov.il/api/3/action/datastore_search?resource_id=142afde2-6228-49f9-8a29-9b6c3a0cbe40&filters=${JSON.stringify(
  //                   payload_2
  //                 )}`
  //               );

  //               setHp(res_2.data.result.records[0].nefah_manoa);
  //             } catch (error) {
  //               //console.log(error);
  //             }
  //           }
  //         } catch (error) {
  //           //console.log(error);
  //           setLoading(false);
  //           setSuccess(false);
  //           setDisableAll(false);
  //         }
  //         setLoading(false);
  //       } else {
  //         setYop("");
  //         setHp("");
  //         setLoading(false);
  //         setSuccess(false);
  //         setLicenseData(null);
  //         setDisableAll(false);
  //       }
  //     };

  //     fetchData();
  //   }
  // }, [licenceNum]);

  // ==== HANDLE CAR API ====
  const handleCarApi = async () => {
    setLoading(true);

    let licensePayload = {
      mispar_rechev: licenceNum,
    };
    // ================================================
    // =========== CALL FIRST LICENSE API =============
    // ================================================
    try {
      const res = await axios.get(
        `https://data.gov.il/api/3/action/datastore_search?resource_id=053cea08-09bc-40ec-8f7a-156f0677aff3&filters=${JSON.stringify(
          licensePayload
        )}`
      );

      // ====================================================
      // =========== IF FIRST API RECORDS EXIST =============
      // ====================================================
      if (res.data.result.records.length > 0) {
        // ====================================================
        // ============== CALL THE SECOND API =================
        // ====================================================
        const second_payload = {
          kinuy_mishari: res.data.result.records[0].kinuy_mishari,
          degem_nm: res.data.result.records[0].degem_nm,
          ramat_gimur: res.data.result.records[0].ramat_gimur,
        };

        const secondApiResponse = await axios.get(
          `https://data.gov.il/api/3/action/datastore_search?resource_id=142afde2-6228-49f9-8a29-9b6c3a0cbe40&filters=${JSON.stringify(
            second_payload
          )}`
        );

        setYop(res.data.result.records[0].shnat_yitzur);
        setHp(secondApiResponse.data.result.records[0].nefah_manoa);
        setManufacturer(secondApiResponse.data.result.records[0].tozar);
        setModel(secondApiResponse.data.result.records[0].kinuy_mishari);

        let firstAPIData = res.data.result.records[0];
        let secondAPIData = secondApiResponse.data.result.records[0];

        let list = Object.entries(vehicleDetailFields);

        let vehicleData = list.map((i) => {
          if (i[0] === "mispar_rechev") {
            return { [i[1]]: firstAPIData.mispar_rechev };
          } else if (i[0] === "tozeret_cd") {
            return { [i[1]]: firstAPIData.tozeret_cd };
          } else if (i[0] === "sug_degem") {
            return { [i[1]]: secondAPIData.sug_degem };
          } else if (i[0] === "tozeret_nm") {
            return { [i[1]]: secondAPIData.tozeret_nm };
          } else if (i[0] === "degem_cd") {
            return { [i[1]]: secondAPIData.degem_cd };
          } else if (i[0] === "degem_nm") {
            return { [i[1]]: secondAPIData.degem_nm };
          } else if (i[0] === "ramat_gimur") {
            return { [i[1]]: secondAPIData.ramat_gimur };
          } else if (i[0] === "ramat_eivzur_betihuty") {
            return { [i[1]]: secondAPIData.ramat_eivzur_betihuty };
          } else if (i[0] === "kvutzat_zihum") {
            return { [i[1]]: secondAPIData.kvutzat_zihum };
          } else if (i[0] === "shnat_yitzur") {
            return { [i[1]]: secondAPIData.shnat_yitzur };
          } else if (i[0] === "degem_manoa") {
            return { [i[1]]: firstAPIData.degem_manoa };
          } else if (i[0] === "mivchan_acharon_dt") {
            return { [i[1]]: firstAPIData.mivchan_acharon_dt };
          } else if (i[0] === "mispar_dlatot") {
            return { [i[1]]: secondAPIData.mispar_dlatot };
          } else if (i[0] === "tokef_dt") {
            return { [i[1]]: firstAPIData.tokef_dt };
          } else if (i[0] === "baalut") {
            return { [i[1]]: firstAPIData.baalut };
          } else if (i[0] === "misgeret") {
            return { [i[1]]: firstAPIData.misgeret };
          } else if (i[0] === "tzeva_cd") {
            return { [i[1]]: firstAPIData.tzeva_cd };
          } else if (i[0] === "tzeva_rechev") {
            return { [i[1]]: firstAPIData.tzeva_rechev };
          } else if (i[0] === "zmig_kidmi") {
            return { [i[1]]: firstAPIData.zmig_kidmi };
          } else if (i[0] === "zmig_ahori") {
            return { [i[1]]: firstAPIData.zmig_ahori };
          } else if (i[0] === "sug_delek_nm") {
            return { [i[1]]: firstAPIData.sug_delek_nm };
          } else if (i[0] === "horaat_rishum") {
            return { [i[1]]: firstAPIData.horaat_rishum };
          } else if (i[0] === "moed_aliya_lakvish") {
            return { [i[1]]: firstAPIData.moed_aliya_lakvish };
          } else if (i[0] === "kinuy_mishari") {
            return { [i[1]]: secondAPIData.kinuy_mishari };
          } else if (i[0] === "nefah_manoa") {
            return { [i[1]]: secondAPIData.nefah_manoa };
          } else if (i[0] === "hanaa_nm") {
            return { [i[1]]: secondAPIData.hanaa_nm };
          } else if (i[0] === "delek_nm") {
            return { [i[1]]: secondAPIData.delek_nm };
          } else if (i[0] === "mazgan_ind") {
            return { [i[1]]: secondAPIData.mazgan_ind };
          } else if (i[0] === "technologiat_hanaa_nm") {
            return { [i[1]]: secondAPIData.technologiat_hanaa_nm };
          } else if (i[0] === "kosher_grira_bli_blamim") {
            return { [i[1]]: secondAPIData.kosher_grira_bli_blamim };
          } else if (i[0] === "kvuzat_agra_cd") {
            return { [i[1]]: secondAPIData.kvuzat_agra_cd };
          } else if (i[0] === "merkav") {
            return { [i[1]]: secondAPIData.merkav };
          } else if (i[0] === "automatic_ind") {
            return { [i[1]]: secondAPIData.automatic_ind };
          } else if (i[0] === "mispar_moshavim") {
            return { [i[1]]: secondAPIData.mispar_moshavim };
          } else if (i[0] === "mishkal_kolel") {
            return { [i[1]]: secondAPIData.mishkal_kolel };
          } else if (i[0] === "koah_sus") {
            return { [i[1]]: secondAPIData.koah_sus };
          } else if (i[0] === "abs_ind") {
            return { [i[1]]: secondAPIData.abs_ind };
          } else if (i[0] === "kosher_grira_im_blamim") {
            return { [i[1]]: secondAPIData.kosher_grira_im_blamim };
          }
        });

        setLicenseData(vehicleData);

        setSuccess(true);
      } else {
        setLicenseData("");
      }

      // ===========================================================
      // =========== IF FIRST API RECORDS DO NOT EXIST =============
      // ===========================================================
    } catch (error) {
      // ==================================================
      // =========== CATCH FIRST API ERROR ================
      // ==================================================
      //console.log(error);
      // toast.error(error.response.data.message);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleImageUpload = (e) => {
    if (images.length < 3) {
      setImages((prev) => [...prev, e.target.files[0]]);
    }
  };

  const handleSubmit = (e) => {
    //console.log("licenceNum", licenceNum);
    let formdata = new FormData();
    formdata.append("carLicenseNumber", licenceNum);
    formdata.append("subscriberId", JSON.parse(user_id));
    formdata.append("isVehicle", true);

    if (description) {
      formdata.append("description", description);
    }

    if (manufacturer) {
      formdata.append("manufacturer", manufacturer);
    }

    if (model) {
      formdata.append("model", model);
    }

    if (hp) {
      formdata.append("hp", hp);
    }

    if (yop) {
      formdata.append("yop", yop);
    }

    if (images.length > 0) {
      images.map((i) => {
        formdata.append("images", i);
      });
    }

    const config = {
      headers: {
        Authorization: token && JSON.parse(token),
      },
    };
    //console.log(formdata, "formdata");
    axios
      .post(`${BASE_URL}/customer/prerequest`, formdata, config)
      .then((res) => {
        if (res.status === 200) {
          const entryPayload = {
            requestIds: [res.data.result.requestId],
          };
          axios
            .post(`${BASE_URL}/customer/request`, entryPayload, config)
            .then((res) => {
              if (res.status === 200) {
                toast.success("Request Created.");
                setIsOpen(false);
              }
            })
            .catch((err) => {
              //console.log(err.response);
              toast.error(err.response.data.message);
            });
        }
      })
      .catch((err) => {
        //console.log(err.response);
      });
  };

  return (
    <div className={styles.mainContainer}>
      <Backdrop setIsOpen={setIsOpen} />
      <div className={styles.container}>
        <span className={styles.close} onClick={() => setIsOpen(false)}>
          x
        </span>

        <div className={styles.content}>
          <p>
            יש להזין מידע ככל שניתן ותמונות רלוונטיות, הבקשה תשלח לכל הספקים
            במערכת
          </p>

          <div className={styles.licenseContainer}>
            <div className={styles.licenseRight}>
              <div className={styles.licenseNum}>
                <div className={styles.wrapper}>
                  <div className={styles.left}>
                    <input
                      type="number"
                      placeholder="מספר הרכב שלך"
                      value={licenceNum}
                      onChange={(e) => setLicenceNum(e.target.value)}
                    />
                  </div>
                  <div className={styles.right}>
                    <img src="/assets/icons/flag.svg" alt="flag" />
                    IL
                  </div>
                </div>
              </div>
              {licenceNum.length >= 5 && (
                <button onClick={handleCarApi} className={styles.licenseBtn}>
                  חיפוש
                </button>
              )}
            </div>

            <button disabled={!licenseData}>תעודת זהות של הרכב</button>

            <div className={styles.dropCard}>
              <ul>
                {licenseData &&
                  licenseData.map(
                    (i, idx) =>
                      i &&
                      Object.values(i)[0] && (
                        <li key={idx}>
                          <p>{Object.keys(i)[0]}:</p>
                          <p>{Object.values(i)[0]}</p>
                        </li>
                      )
                  )}
              </ul>
            </div>
          </div>

          {loading && <Loader />}

          {success && (
            <div className={styles.details}>
              <input
                type="text"
                value={manufacturer}
                disabled={disableAll}
                onChange={(e) => setManufacturer(e.target.value)}
              />
              <input
                type="text"
                value={model}
                disabled={disableAll}
                onChange={(e) => setModel(e.target.value)}
              />
              <input
                type="text"
                value={yop}
                disabled={disableAll}
                onChange={(e) => setYop(e.target.value)}
              />
              <input
                type="text"
                value={hp}
                disabled={disableAll}
                onChange={(e) => setHp(e.target.value)}
              />
            </div>
          )}

          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>

          <label>העלה תמונות ( עד 3 תמונות)</label>
          <div className={styles.upload}>
            <input type="file" onChange={handleImageUpload} />
            <div className={styles.box}>
              <span>+</span>
            </div>
          </div>

          <div className={styles.preview}>
            {images.length > 0 &&
              images.map((i) => (
                <img src={URL.createObjectURL(i)} alt="preview" />
              ))}
          </div>

          <button onClick={handleSubmit}>שלח בקשה</button>
        </div>
      </div>
    </div>
  );
};

export default DemolishModal;

import styles from "../../styles/_features/main/stepCard.module.scss";

const StepCard = ({ icon, title, text, position }) => {
  const classname =
    position === "1"
      ? styles.one
      : position === "2"
      ? styles.two
      : position === "3"
      ? styles.three
      : styles.four;

  return (
    <div className={`${styles.container} ${classname}`}>
      <img src={icon} alt={title} />
      <div className={styles.text}>
        <h6>{title}</h6>
        <p>{text}</p>
      </div>

      {position !== "4" && (
        <img
          src={
            position === "1"
              ? "/assets/images/left_arrow_vector.svg"
              : position === "2"
              ? "/assets/images/right_arrow_vector.svg"
              : position === "3"
              ? "/assets/images/left_arrow_vector.svg"
              : ""
          }
          alt="vector"
          className={styles.vector}
        />
      )}
    </div>
  );
};

export default StepCard;

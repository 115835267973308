import { useEffect } from "react";
import CATEGORIES from "../../api/services/CATEGORIES";
import styles from "../../styles/_features/createRequest/categories.module.scss";

const Categories = ({
  categories,
  setCategories,
  category,
  setCategory,
  setParts,
  vehicle,
  setCollapsePartsSection,
}) => {
  // FETCH CATEGORIES
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await CATEGORIES.get();
        if (res.result.length > 0) {
          let filtered = [];

          filtered = res.result.filter((j) => j.vehicle === vehicle);

          if (filtered.length > 0) {
            setCategories(filtered);
          } else {
            setCategories([]);
          }
        } else {
          setCategories([]);
        }
      } catch (error) {
        //console.log(error);
        setCategories([]);
      }
    };

    fetchCategories();
  }, [vehicle]);

  return (
    <div className={styles.container}>
      <h2>
        <img src="/assets/icons/categories.svg" alt="categories" />
        קטגוריות חלפים
      </h2>

      <div className={styles.categories}>
        {categories &&
          categories.map((i) => (
            <div
              className={`${category === i._id ? styles.active : ""} ${
                styles.category
              }`}
              key={i._id}
              onClick={() => {
                setCategory(i._id);
                setCollapsePartsSection(true);
              }}
            >
              <span>{">"}</span>
              <p>{i.name}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Categories;

import { useState, useEffect } from "react";
import Layout from "../layouts/Layout";
import styles from "../styles/_pages/otp.module.scss";
import { put, post, get } from "../api/client";
import Main from "../pages/Main";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";

const Otp = () => {
  const [field, setField] = useState("");
  const [err, setErr] = useState("");
  const [verifySent, setVerifySent] = useState(null);
  const [succeed, setSucceed] = useState(false);
  const [isVerified, setIsverifed] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);

    const checkVerify = async () => {
      const res = await get(
        "https://api.get-part.co.il:9072/api/v1/customer/profile"
      );
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
      if (res.status === 200) {
        const {
          result: { isEmailVerified },
        } = res;
        setIsverifed(isEmailVerified);
        if (isEmailVerified) {
          navigate(paths.main); // Redirect to new path if email is verified
        }
      }
    };
    checkVerify();
  }, []);

  const handleSubmit = async () => {
    try {
      const res = await put(
        "https://api.get-part.co.il:9072/api/v1/customer/verifyEmailOTP",
        { otp: field }
      );
      console.log(res);
      if (res.status == 200) {
        setSucceed(true);
      }
    } catch (error) {
      setErr(error.response.data.message);
    }
  };

  const sendEmailAgain = async () => {
    try {
      const response = await post(
        "https://api.get-part.co.il:9072/api/v1/customer/sendEmailOTP"
      );
      setVerifySent("קוד האימות נשלח בהצלחה לכתובת המייל איתה נרשמת");
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    setIsButtonDisabled(true);

    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 60000); // 60 seconds in milliseconds
  };

  return (
    <Layout>
      <div className={styles.container}>
        {!succeed && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "1vh",
              backgroundColor: "white",
              padding: "30px",
            }}
          >
            <p style={{ fontSize: "24px", textAlign: "center" }}>
              אנא הכנס את הקוד שנשלח אליך במייל
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                maxLength={4}
                inputMode={"numeric"}
                onChange={(e) => {
                  setField(e.target.value);
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "white !important",
                  height: "45px",
                  border: "1px solid cornflowerblue",
                  borderRadius: "5px",
                  fontSize: "18px",
                  textAlign: "center",
                  fontFamily: "Open Sans",
                  width: "50%",
                }}
              ></input>
              <button
                onClick={handleSubmit}
                style={{
                  backgroundColor: "white",
                  padding: "10px",
                  fontSize: "18px",
                  textAlign: "center",
                  fontFamily: "Open Sans",
                  border: "1px solid cornflowerblue",
                  borderRadius: "5px",
                  cursor: "pointer",
                  height: "45px",
                }}
              >
                אימות
              </button>
            </div>
            <button
              style={{
                cursor: "pointer",
                backgroundColor: "transparent",
                border: "none",
                fontSize: "18px",
                textAlign: "center",
                fontFamily: "Open Sans",
              }}
              onClick={() => {
                sendEmailAgain();
                handleClick();
              }}
              disabled={isButtonDisabled}
            >
              לא קיבלתי קוד למייל
            </button>
            {verifySent && (
              <div>
                <span>{verifySent}</span>
              </div>
            )}
            {err && (
              <span
                style={{
                  backgroundColor: "white",
                  color: "red",
                }}
              >
                {err}
              </span>
            )}
          </div>
        )}
        {succeed && <div>כתובת המייל שלך אומתה בהצלחה! חיפוש נעים</div>}
      </div>
    </Layout>
  );
};

export default Otp;

import React, { useState, useEffect  }from 'react';
import Layout from "../layouts/Layout";
import styles from "../styles/_pages/regulations.module.scss";

const Regulations = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <Layout>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <h1>תקנון גט פארט</h1>

                    <h2>מבוא</h2>

                    <p>בשימושך בפלטפורמה של גט פארט (באתר או באפליקציה) (״האתר״, ״המפעיל״ בהתאמה), ובכלל זה בתכנים הכלולים בו ובשירותים הפועלים במסגרתו, הינך מסכים להיות קשור ולפעול על פי תנאיו ותנאים מחייבים נוספים, לרבות מדיניות הפרטיות של האתר וכן להוראות וכללים נוספים שייתכן ויוצגו בפנייך במהלך השימוש באתר (״תנאי האתר״). אנא הקפד לקוראם בקפידה. הגלישה והשימוש באתר כפופה להסכמתך המקדמית לתנאי האתר לרבות בתקנון זה ובמדיניות הפרטיות, כפי שיעודכנו על ידי המפעיל מעת לעת, והוא מהווה חוזה מחייב בין המבקר בו לבין המפעיל. בתקנון השימוש המילה "שימוש" על הטיותיה השונות תכלול את הפעולות הבאות: ביקור באתר ו/או גלישה באתר ו/או רישום באתר ו/או שימוש באחד משירותי/מוצרי האתר ו/או כל שימוש אחר באתר.</p>

                    <p>תשומת לבך כי תנאי האתר הינם מחייבים ועשויים להשתנות מעת לעת וכי מוטלת עליך החובה להתעדכן בהם. אם אינך מסכים לתנאי האתר באופן מלא, הינך מתבקש להימנע מכל שימוש בו.</p>

                    <br />

                    <h2>תנאי שימוש</h2>

                    <p className={styles.underline}>גט פארט הינה הבעלים והמפעילה של האתר והאפליקציה המשמשים למציאת חלקי חילוף לרכבים משאיות אופנועים וכלי תחבורה שונים .</p>

                    <p>הינך רשאי להשתמש באתר של גט פארט בהתאם לתנאי האתר וכן להוראות הדין ובלבד שיעשה באופן שאינו פוגע בזכויות צד שלישי כלשהו ובאופן שמכבד את פרטיות המשתמשים בו כמפורט במדיניות הפרטיות של האתר. השימוש באתר מיועד למטרות פרטיות בלבד ולא למטרות מסחריות. תנאי האתר מנוסחים בלשון זכר או נקבה מטעמי עיצוב או נוחות בלבד, והם מתייחסים באופן שווה לכל המינים.</p>

                    <p>השימוש באתר מחייב רישום ומתן פרטים אישיים מזהים, בכלל זאת. האתר מאפשר פרסום תכנים מטעמך, עליך להקפיד והינך מתחייב על חוקיותם ומאשר שאתה בעל הזכויות בהם.
                    </p>

                    <p>השימוש באתר יעשה באמצעות מנוי, ברכישת שימוש חד פעמי או בכל דרך אחרת שיאפשר המפעיל. השימוש הינו לשימוש אישי בלבד. אין לאפשר לצד שלישי כלשהו להשתמש ב"מנוי" בין בתמורה ובין שלא בתמורה.</p>

                    <p>הינך מצהיר ומתחייב שלא לאסוף תכנים ונתונים מהאתר בכל צורה שהיא (למעט על דרך של צפייה רגילה במודעות ככל משתמש סביר), לרבות בכל אמצעי טכנולוגי ו/או אלקטרוני ו/או ממוחשב ו/או באמצעות תוכנות אוטומטיות, וכן שלא להעתיקם, לשכפלם, לפרסמם או להציגם. כל שימוש אחר יהווה משום שימוש בניגוד לתנאי האתר, על כל המשתמע מכך, והמפעיל שומר לעצמו את הזכות לחסום את השימוש שלך בשירותים וכן לתבוע פיצוי מתאים וזאת מבלי לגרוע מכל סעד אחר העומד לזכות המפעיל.</p>

                    <p>מבלי לגרוע מהאמור לעיל המשתמש מצהיר ומתחייב שלא להעתיק ו/או לשכפל ו/או לעדכן ו/או לשנות ו/או להפיץ ו/או לפרסם ברבים כל תוכן המצוי באתר. שלא לבצע כל פעולה אשר תייצר ו/או אשר עלולה לייצר עומס על מערכות ושרתי האתר. שלא לבצע כל פעולה המתערבת ו/או המפריעה בדרך הפעולה הרגילה של האתר והשירותים. שלא לעקוף או לנסות לעקוף כל אמצעי טכנולוגי הקיים באתר ו/או במערכות הקשורות אליו אשר תפקידו למנוע אלו מן הפעילויות האסורות תחת תקנון זה.</p>

                    <p>המשתמש מתחייב שלא לפרסם או להזין לאתר תכנים פוגעניים וכן תכנים המפרים זכויותיהם של אחרים, לרבות חומר הפוגע או מפר זכויות יוצרים/סימני מסחר של גורם אחר, בכללם קבצים מוסיקליים, אמצעים לעקיפה הסרה או ביטול של הגנות דיגיטליות על תוכנות וקבצי מחשב, תמונות/סרטים מוגנים או תמלילים, תווים, טקסטים או עבודות אומנות שנוצרו בידי אחרים; כל תוכנת מחשב, קוד או יישום שיש בו כדי לפגוע באתר, כדג תוכנה עויינת, וירוס, תולעים, יישומים מזיקים; כל חומר בלתי חוקי, מידע בטחוני רגיש; חומר המהווה לשון הרע או בעל אופי עוין, מאיים, גס, או גזעני; חומר החושף פרטים אישיים על אחר, מבלי שהוא עצמו מסר אותו ואשר יש בו לפגוע בפרטיותו; חומר בעל אופי מיני בוטה או חומר פורנוגרפי, המפר הוראות כל דין או שעלול לפגוע בררגשות הציבור; פרטים אישיים או כל חומר הנוגע לקטינים ללא אישור אפוטרופוסם החוקי; כל חומר או מסר המפר הוראות כל דין; כל חומר המנוגד לכללי השימוש המקובלים באינטרנט או העלול לגרום נזק או לפגוע במשתמשי אינטרנט בכלל, ובלקוחות האתר בפרט.</p>

                    <p>היה ותפר אחד או יותר מן התנאים שלעיל, אתה תשפה את החברה בגין כל נזק שיגרם לה כתוצאה מהפרות אלו.</p>

                    <p>המפעיל רשאי לחסום לאלתר את גישתך לפלטפורמה במקרה בו פעלת בניגוד לתקנון זה ו/או להוראות כל דין, או מכל סיבה סבירה אחרת. </p>

                    <br />

                    <h2>אחריות מוגבלת</h2>

                    <p>האתר, השירותים והמידע שבמסגרתו ניתנים לשימוש כמות שהם “As Is” - מובהר כי המשתמש, והוא בלבד, יישא באחריות בלעדית לאופן שבו הוא עושה בהם שימוש ולכל הנובע מכך. המפעיל מספק פלטפורמה ואינו צד בכל התקשרות שתבוצע או שלאו, וככל שבוצעה התקשרות כאמור, המפעיל לא יישא בכל אחריות להתקשרות כאמור או לטעויות ו/או שגיאות, ככל שישנם או שיהיו בנתונים המופיעים בפלטפורמה וכן לכל אובדן, הפסד, חסרון כיס או נזק ישיר, עקיף או אחר שיגרם במישרין או בעקיפין, לך, או למי מטעמך, או לכל צד שלישי אחר, כתוצאה מהשימוש בהם.</p>

                    <p>הפלטפורמה מאפשרת למשתמשים לפרסם תוכן ו/או מידע מגוון, הכולל חלפים שונים, מחירים ונתונים רבים נוספים. מידע זה אינו ניתן ע״י המפעיל ואינו נעשה מטעמו, אלא ניתן כשירות למשתמשים בלבד. המשתמש מצהיר כי ידוע לו כי אין ביכולת המפעיל לבקר תכנים המוזנים לאתר על ידי אחרים, וכן אין ביכולתו למנוע שגיאות, ליקויים, וטעויות במידע או בתכנים אלה וכן כי המפעיל אינו אחראי לאיכות, אמיתות והתאמת המוצרים הנמכרים ו/או המוצעים, ו/או איכות השירותים המסופקים ו/או המוצעים, לצרכיך או למטרותיך.</p>

                    <p>מובהר ומודגש כי אין לבצע כל התקשרות או לקבל כל החלטה בהסתמך על התכנים או המידע הניתן במסגרת האתר מבלי לבצע בדיקה עצמאית וכן לוודא את נכונותם אצל הגורמים הרלבנטיים.</p>

                    <p>המפעיל אינו מתחייב כי השירות יסופק באופן רציף, ללא הפסקות או תקלות וכן שומר לעצמו את הזכות למנוע באופן זמני או לצמיתות, השתתפותם של משתמשים לפי שיקול דעתו הבלעדי.</p>

                    <br />

                    <h2>מדיניות הפרטיות</h2>

                    <p>השימוש באתר דורש מסירת מידע אישי במסגרתו תתבקש לספק מספר נתונים אישיים כדוגמת: שם מלא, מספר טלפון, כתובת דואר אלקטרוני ועוד. נתונים אלה יועברו ויישמרו במאגר המידע של המפעיל. יודגש, כי מסירת הנתונים הנ"ל על ידך אינה מחויבת על פי חוק, אולם ללא מסירתם לא תוכל להשתמש בשירותים המוצעים באתר.</p>

                    <p>שימושך באתר, כמו גם שימושם של משתמשים אחרים, עשוי להביא לאיסוף מידע ונתונים אודות סוג הפעילות אותה ביצעת באתר. מידע זה וכן המידע האישי לגביך יישמרו במאגר הנתונים של המפעיל והוא יעשה בהם שימוש בכפוף להוראות כל דין.</p>

                    <p>המפעיל רשאי לעשות שימוש במידע האישי וכן במידע שנאסף אודותיך בין היתר לצורך שליחת עדכוני מערכת ועדכונים בקשר עם חיפושים ו/או נתונים שתפרסם, שליחת דואר אלקטרוני ו/או הודעות SMS לטלפונים ויצירת קשר עמך בדרכים אחרות בכפוף להוראות הדין (לרבות בדרך של דברי פרסומת, דיוור ישיר ושירותי דיוור ישיר).</p>

                    <p>ייתכן והמפעיל יעשה שימוש כללי במידע שנאסף אודותיך לצרכים שיווקיים או לצורך מחקרים סטטיסטיים של המפעיל ו/או צדדים שלישיים, לצורך שיפור השירותים הניתנים במסגרת האתר, שינוי וייעול מבנה האתר, הרחבת/צמצום תחומי פעילותו ומטרות נוספות, לפי שיקול דעת המפעיל וזאת לטובת המשתמשים והפעילות של האתר. כמו כן המפעיל רשאי לעשות שימוש במידע האישי ובמידע שנאסף אודותיך על מנת ליצור עימך קשר ולעדכנך בדבר מבצעים ו/או שירותים רלבנטיים אחרים.</p>

                    <p>המפעיל יעשה מאמץ סביר להגנה ולאבטחת המידע הנמסר על ידך, אולם אין ביכולתו לספק אבטחה מושלמת ומוגנת מחדירות בלתי מורשות ומשימוש אסור על ידי צדדים שלישיים, ולכן לא תישא בכל אחריות לכל נזק שיגרם לך כתוצאה ממקרים של שימוש במידע באופן האמור ו/או כתוצאה ממעשים או מחדלים של גורמים בלתי מורשים אשר אינם בשליטתה.</p>

                    <p>במסגרת השירותים יתאפשר לך ליצור קשר ישיר עם צדדים שלישיים, בהם משתמשים אחרים, במהלכו יתכן ותתבקש להעביר פרטים ו/או מידע כלשהו אודותיך, אשר עשוי להיאסף על ידם ללא הסכמתך. המפעיל אינו אחראי, במישרין או בעקיפין, לכל שימוש שעשוי להיעשות על ידם ולא יישא בכל נזק שיגרם משימוש בפרטיך על ידם, לרבות פגיעה בפרטיותך.</p>

                    <p>סיום התקשרות </p>

                    <br />

                    <h2>קניין רוחני</h2>

                    <p>המשתמש מצהיר כי ידוע לו כי כל זכויות הקניין הרוחני, זכויות היוצרים, הסודות המסחריים, זכויות הבעלות ו/או השימוש ביחס לאתר הינן של המפעיל בלבד, או של צדדים שלישיים שהתירו לו את השימוש בתוכן הרלבנטי ולמשתמש לא תהיה כל זכות בהם והשימוש שייעשה במידע זה הינו לצרכיו האישיים בלבד.</p>

                    <p>אין להעתיק, לשכפל, להפיץ, לפרסם או להשתמש בכל דרך אחרת במידע כלשהו מן האתר, אלא אם ניתנה הסכמה לכך מראש ובכתב מטעם המפעיל.</p>

                    <br />

                    <h2>סיום התקשרות</h2>

                    <p>הסיום יעשה על ידי שליחת מייל/וואטאפ /טלפון לחברתנו כפי הפרטים שמפורטים בצור קשר והביטול יעשה בסיום החודש שלאחריו.</p>

                    <br />

                    <h2>בהתאם לחוק הגנת הצרכן מדיניות ביטולים תשלום</h2>

                    <p>ניתן יהיה לבטל כל עסקה תוך 48 שעות מרגע תשלום בעבור השירות, הבקשה תעשה ע"י פנייה לחברנו לפי הפרטים המצוינים בדף צור קשר באתר, מלבד חיפוש חד פעמי.</p>

                    <p>במקרה של תשלום בעבור שירות מתמשך, החיוב יבוטל מיד בתום החודש הנוכחי בעת קבלת הבקשה והניתוק יתבצע ולא יאוחר מ 2 ימי עסקים מרגע הבקשה.</p>

                    <p>אך ורק בחיוב  של חיפוש חד פעמי – במקרה בו התקבל תשלום בעבור חיפוש בודד ולא נמצא ספק שהחלק קיים אצלו במלאי / לא ניתנה הצעה כל שהיא במערכת לגבי אותו חלק התשלום החד פעמי יוחזר במלואו.</p>

                    <br />

                    <h2>הוראות שונות נוספות</h2>

                    <p>על השימוש באתר יחולו אך ורק דיני מדינת ישראל. מקום השיפוט הבלעדי בגין כל דבר ועניין בנוגע לשירותי האתר, מוקנה באופן בלעדי לבתי המשפט המוסמכים, אשר ידו בעניין בהתאם לדיני מדינת ישראל.</p>

                    <p>גט פארט פועלת לספק ללקוחותיה פלטפורמה יעילה ואיכותית, בכל שאלה או התייעצות ניתן לפנות אלינו בכתובת getpart4@gmail.com</p>


                </div>
            </div>
        </Layout>
    )
}

export default Regulations
import { get, post, put } from "../client";
import {
  CREATE_REQUEST,
  CREATE_SEARCH_ENTRY,
  SUBSCRIBER_REQUESTS,
  MOVE_TO_ARCHIVE_SUB,
} from "../routes";

export default {
  create_request: async (data) => await post(CREATE_REQUEST, data),

  create_search_entry: async (data) => await post(CREATE_SEARCH_ENTRY, data),

  subscriber_requests: async () => await get(SUBSCRIBER_REQUESTS),

  move_to_archive: async (data) => await put(MOVE_TO_ARCHIVE_SUB, data),
};

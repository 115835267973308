export const paths = {
  login: "/auth/login",
  register: "/auth/register",
  forgot: "/auth/forgot",
  otp: "/app/customer/otp",
  main: "/app/customer/",
  contact: "/app/contact",

  subscriberHistory: "/app/customer/my-requests",
  subscriberArchive: "/app/subscriber/archive",
  latestSubscriberRequests: "/app/subscriber/latest-searches",

  createRequest: "/app/customer/create-request",

  supplierHistory: "/app/supplier/customer-requests",
  supplierArchive: "/app/supplier/archive",
  latestSupplierRequests: "/app/supplier/latest-searches",
  supplierManufacturers: "/app/supplier/manufacturers",

  regulations: "/app/regulations",
};

import { useState } from "react";
import Spinner from "../../components/Spinner";
import styles from "../../styles/_features/createRequest/cart.module.scss";

const Cart = ({
  selectedParts,
  setSelectedParts,
  handleSubmit,
  loading,
  disabled,
  isFormValid, // Receive isFormValid as a prop
}) => {
  const [selectedImages, setSelectedImages] = useState([]);

  const handleDescChange = (event, index) => {
    let temp = selectedParts;
    temp[index] = {
      ...temp[index],
      desc: event.target.value,
    };
    setSelectedParts(temp);
  };

  const handleImgChange = (event, index) => {
    let temp = selectedParts;
    temp[index] = {
      ...temp[index],
      img: event.target.files[0],
      imgPreview: URL.createObjectURL(event.target.files[0]),
    };

    setSelectedImages((prev) => [
      ...prev,
      {
        idx: index,
        img: URL.createObjectURL(event.target.files[0]),
      },
    ]);
  };

  return (
    <div className={styles.container}>
      <h1>
        <img src="/assets/icons/cart.svg" alt="cart" />
        חלקים
      </h1>

      <ul>
        {selectedParts?.map((i, idx) => (
          <li key={i._id}>
            <p className={styles.name}>{i.name}</p>
            <div className={styles.comment}>
              <input
                style={{ width: "100%" }}
                type="text"
                className={styles.commentInput}
                name={i._id}
                value={i._desc}
                placeholder="הערות לספק"
                onChange={(e) => handleDescChange(e, idx)}
              />
              {selectedImages.length > 0 &&
              selectedImages.find((x) => x.idx === idx) ? (
                <img
                  src={selectedImages.find((x) => x.idx === idx).img}
                  alt={i.name}
                  className={styles.imgPreview}
                />
              ) : (
                <div className={styles.inputIconContainer}>
                  <input
                    type="file"
                    name="partImg"
                    className={styles.imgInput}
                    onChange={(e) => handleImgChange(e, idx)}
                  />
                  <img src="/assets/icons/camera.svg" alt="camera" />
                </div>
              )}
              <button
                style={{
                  width: "auto",
                  background: "none",
                  display: "grid",
                  alignContent: "center",
                  padding: "0",
                }}
                onClick={() =>
                  setSelectedParts(
                    selectedParts.filter((x) => {
                      if (i._id) {
                        return x._id !== i._id;
                      } else {
                        return x.name !== i.name;
                      }
                    })
                  )
                }
              >
                <img
                  src="/assets/icons/bin.svg"
                  alt="delete"
                  className={styles.deleteIcon}
                />
              </button>
            </div>
          </li>
        ))}
      </ul>

      <button
        onClick={handleSubmit}
        disabled={disabled || loading || !isFormValid}
      >
        {loading ? <Spinner /> : "שליחה"}
      </button>
    </div>
  );
};

export default Cart;

import { useState, useEffect } from "react";
import Modal from "../components/Modal";
import Card from "../features/login/Card";
import Layout from "../layouts/Layout";
import styles from "../styles/_pages/login.module.scss";

const Login = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <img
            src="/assets/images/login.png"
            alt="graphic"
            className={styles.mainImg}
          />
          <Card setIsModalOpen={setIsModalOpen} />
        </div>

        {isModalOpen && <Modal setIsOpen={setIsModalOpen} />}
      </div>
    </Layout>
  );
};

export default Login;

import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AUTH from "../../api/services/AUTH";
import SUBSCRIBER from "../../api/services/SUBSCRIBER";
import { SearchContext } from "../../App";
import Spinner from "../../components/Spinner";
import { BASE_URL } from "../../config";
import { paths } from "../../routes/paths";
import { isTokenExpired } from "../../utils/isTokenExpired";
import styles from "../../styles/_features/login/card.module.scss";
import { get } from "../../api/client";

const Card = ({ setIsModalOpen }) => {
  const navigate = useNavigate();

  const token = localStorage.getItem("authToken");

  // states
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  // global state
  const {
    vehicleVal,
    manufacturerVal,
    modelVal,
    subModelVal,
    yopVal,
    gearTypeVal,
    engineTypeVal,
    hpVal,
    licenceNumber,
    disableAllField,
    manuList,
    modelsList,
    selParts,
  } = useContext(SearchContext);

  const [vehicle, setVehicle] = vehicleVal;
  const [manufacturer, setManufacturer] = manufacturerVal;
  const [model, setModel] = modelVal;
  const [subModel, setSubModel] = subModelVal;
  const [yop, setYop] = yopVal;
  const [gearType, setGearType] = gearTypeVal;
  const [engineType, setEngineType] = engineTypeVal;
  const [hp, setHp] = hpVal;
  const [licenceNum, setLicenceNum] = licenceNumber;
  const [disableAll, setDisableAll] = disableAllField;
  const [manufacturers, setManufacturers] = manuList;
  const [models, setModels] = modelsList;
  const [selectedPartsCopy, setSelectedPartsCopy] = selParts;

  // submit form
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setEmailError("שדה חובה");
    }
    if (!password) {
      setPasswordError("שדה חובה");
    }

    if (email && password) {
      const emailRegex = /\S+@\S+\.\S+/;
      let payload;

      if (emailRegex.test(email)) {
        payload = {
          email: email,
          password,
        };
      } else {
        payload = {
          username: email,
          password,
        };
      }

      setLoading(true);
      try {
        const data = await AUTH.login(payload);
        toast.success(data.data.message);

        let token = JSON.stringify(data.data.result.authToken);
        let user_id = JSON.stringify(data.data.result.user._id);
        let user_type = JSON.stringify(data.data.result.user.userType);
        // console.log(user_type, "user_typeuser_typeuser_type");
        let searchesLeft = JSON.stringify(data.data.result.user.searchesLeft);

        localStorage.setItem("authToken", token);
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("user_type", user_type);
        localStorage.setItem("searches_left", searchesLeft);

        const profileRes = await get(
          "https://api.get-part.co.il:9072/api/v1/customer/profile"
        );

        // FOR SUBSCRIBER
        if (data.data.result.user.userType === "SUBSCRIBER") {
          // IF THE USERS COMES FROM THE SEARCH PAGE
          if (localStorage.getItem("isCartClicked")) {
            // IF THERE ARE NO PARTS SELECTED THEN REDIRECT TO MAIN PAGE
            if (!selectedPartsCopy) {
              localStorage.removeItem("isCartClicked");
              navigate(paths.main);
            }

            // IF THE USER HAS ALREADY SELECTED THE PARTS THEN
            else {
              const selectedParts = selectedPartsCopy;

              // CREATE REQUEST ===========
              const fun = (part_id, part_img, part_desc, part_name, custom) => {
                let formdata = new FormData();

                formdata.append("subscriberId", JSON.parse(user_id));

                if (custom) {
                  formdata.append("customPartName", part_name);
                } else {
                  formdata.append("part", part_id);
                }

                if (part_img) {
                  formdata.append("images", part_img);
                }

                if (part_desc) {
                  formdata.append("description", part_desc);
                }

                if (manufacturer) {
                  formdata.append("manufacturer", manufacturer);
                }

                if (model) {
                  formdata.append("model", model);
                }

                if (subModel) {
                  formdata.append("subModel", subModel);
                }

                if (yop) {
                  formdata.append("yop", yop);
                }

                if (gearType) {
                  formdata.append("gearType", gearType);
                }

                if (engineType) {
                  formdata.append("engineType", engineType);
                }

                if (hp) {
                  formdata.append("hp", hp);
                }
                if (licenceNumber) {
                  formdata.append("carLicenseNumber", licenceNumber[0]);
                }

                return new Promise((resolve) => {
                  const config = {
                    headers: {
                      Authorization: token && JSON.parse(token),
                    },
                  };

                  axios
                    .post(`${BASE_URL}/customer/prerequest`, formdata, config)
                    .then((res) => {
                      console.log(res);
                      resolve(res.data.result.requestId);
                    })
                    .catch((err) => {
                      isTokenExpired(err.response.data.status, navigate);
                    });
                });
              };

              let responses = [];

              const callMe = async () => {
                let promises = selectedParts.map((i) => {
                  return fun(i._id, i.img, i.desc, i.name, i.custom);
                });

                responses = await Promise.all(promises);
                // console.log(responses);

                if (responses) {
                  // call search API here!
                  const entryPayload = {
                    requestIds: responses,
                  };

                  try {
                    const res = await SUBSCRIBER.create_search_entry(
                      entryPayload
                    );
                    if (res.status === 200) {
                      toast.success("Request Created.");
                      navigate(paths.subscriberHistory);
                      localStorage.removeItem("isCartClicked");

                      // if (profileRes.result.isEmailVerified) {
                      //   navigate(paths.createRequest);
                      // } else {
                      //   navigate(paths.main);
                      // }
                    }
                  } catch (error) {
                    console.log(error);
                    isTokenExpired(error.response.data.status, navigate);
                    toast.error(error.response.data.message);
                    localStorage.removeItem("isCartClicked");
                    setIsModalOpen(true);
                  }
                }
              };

              callMe();

              // CREATE REQUEST END =======
            }
          }

          // IF THE USERS DOES NOT COME FROM THE SEARCH PAGE
          else {
            navigate(paths.main);
          }
        }

        // FOR SUPPLIER
        else {
          navigate(paths.supplierHistory);
        }
      } catch (error) {
        toast.error(error.response.data.message);
        console.log(error);
      }
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h3>התחברות למערכת</h3>
      <p>
        ברוכים השבים ל-Get Part מנוע חיפוש חלקי החילוף לרכב המתקדם ביותר הכניסו
        שם משתמש וסיסמה כדי להיכנס למערכת
      </p>

      <form onSubmit={handleSubmit}>
        <div className={styles.fieldWrapper}>
        <label> הזינו את טלפון או המייל שלכם</label>

          <div className={styles.field}>
            <img src="/assets/icons/smartphone.svg" alt="email" />
            <input
              type="text"
              placeholder="טלפון / מייל"
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {emailError && <small className={styles.error}>{emailError}</small>}
        </div>

        <div className={styles.fieldWrapper}>
        <label> הזינו את הסיסמה שלכם</label>

          <div className={styles.field}>
            <img src="/assets/icons/lock.svg" alt="password" />
            <input
              type="password"
              placeholder="סיסמה"
              autoComplete="off"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {passwordError && (
            <small className={styles.error}>{passwordError}</small>
          )}
        </div>

        <button type="submit">{loading ? <Spinner /> : "התחברות"}</button>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            gap: "1rem",
          }}
        >
          <span className={styles.reset} onClick={() => navigate(paths.forgot)}>
            שכחתי סיסמה
          </span>
          <span
            style={{
              border: "1px solid blue",
              padding: "0.333rem 0",
              width: "60%",
              margin: "0 auto",
              textDecoration: "none",
              fontWeight: "700",
              borderRadius: "8px",
              backgroundColor: "#f3f4f6",
            }}
            className={styles.reset}
            onClick={() => navigate(paths.register)}
          >
            הרשמה למערכת
          </span>
        </div>
      </form>

      {/* <img src="/assets/icons/logo_4.svg" alt="logo" className={styles.logo} /> */}
    </div>
  );
};

export default Card;

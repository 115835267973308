import { useState } from "react";
import { toast } from "react-toastify";
import PAYMENT from "../api/services/PAYMENT";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/es/styles-compiled.css";
import Spinner from "./Spinner";
import styles from "../styles/_components/paymentModal.module.scss";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";

const style = {
  height: "100vh",
  width: "100%",
  position: "fixed",
  left: "0px",
  top: "0px",
  opacity: 0.45,
  background: "#000000 0% 0% no-repeat padding-box",
};

const Backdrop = ({ setIsOpen }) => (
  <div style={style} onClick={() => setIsOpen(false)}></div>
);

const PaymentModal = ({ setIsOpen, planID, setIsOpen2 }) => {
  const navigate = useNavigate();

  let subscriberID = localStorage.getItem("user_id");

  const [ccno, setCcno] = useState("");
  const [expDate, setExpDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [name, setName] = useState("");
  const [focus, setFocus] = useState("");
  const [holderId, setHolderId] = useState("");

  const [loading, setLoading] = useState(false);

  function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
  }

  const onExpDateChange = (value) => {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      subscriber: JSON.parse(subscriberID),
      plan: planID,
      ccno,
      expDate: expDate.replace("/", ""),
      cvv,
      holderId,
    };

    setLoading(true);
    try {
      const res = await PAYMENT.subscribe(payload);
      setIsOpen(false);
      setIsOpen2(false);
      toast.success(res.data.message);
      navigate(paths.subscriberHistory);
    } catch (error) {
      //console.log(error);
      setIsOpen(false);
      setIsOpen2(false);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <div className={styles.mainContainer}>
      <Backdrop setIsOpen={setIsOpen} />
      <div className={styles.container}>
        {!loading && (
          <span className={styles.close} onClick={() => setIsOpen(false)}>
            x
          </span>
        )}
        <div className={styles.content}>
          <h2 className={styles.heading1}>פרטי כרטיס</h2>

          <Cards
            cvc={cvv}
            expiry={expDate}
            focused={focus}
            name={name}
            number={ccno}
          />

          <form onSubmit={handleSubmit}>
            <div className={styles.row}>
              <div className={styles.field}>
                <label>מספר כרטיס</label>
                <input
                  type="text"
                  placeholder="מספר כרטיס"
                  value={ccno}
                  onChange={(e) => setCcno(e.target.value)}
                  onFocus={(e) => setFocus(e.target.value)}
                />
              </div>
              <div className={styles.field}>
                <label>שם מלא</label>
                <input
                  type="text"
                  placeholder="שם מלא"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onFocus={(e) => setFocus(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <label>תוקף כרטיס</label>
                <input
                  type="text"
                  placeholder="MM/YY"
                  value={expDate}
                  maxLength={5}
                  // pattern="\d\d/\d\d"
                  onChange={(e) => setExpDate(onExpDateChange(e.target.value))}
                  onFocus={(e) => setFocus(e.target.value)}
                />
              </div>
              <div className={styles.field}>
                <label>CVV/CVC</label>
                <input
                  type="text"
                  placeholder="CVV/CVC"
                  value={cvv}
                  maxLength={4}
                  onChange={(e) => setCvv(e.target.value)}
                  onFocus={(e) => setFocus(e.target.value)}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                <label>תעודת זהות</label>
                <input
                  type="text"
                  placeholder="תעודת זהות"
                  value={holderId}
                  onChange={(e) => setHolderId(e.target.value)}
                  onFocus={(e) => setFocus(e.target.value)}
                />
              </div>
            </div>

            <button>{loading ? <Spinner /> : "שלח"}</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;

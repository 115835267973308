import styles from "../styles/_components/subscribeCard.module.scss";

const SubscribeCard = ({
  id,
  plan,
  text,
  price,
  duration,
  variant,
  setIsSubModalOpen,
  setPlanID,
  type,
  setIsOpen,
}) => {
  const classname = variant === "primary" ? styles.primary : styles.secondary;

  // Redirect to the WhatsApp
  const handleButtonClick = () => {
    window.location.href =
      "https://api.whatsapp.com/send/?phone=972528098805&text=%D7%94%D7%99%D7%99,%20%D7%91%D7%A7%D7%A9%D7%A8%20%D7%9C%D7%9E%D7%A0%D7%95%D7%99%20%D7%94%D7%97%D7%95%D7%93%D7%A9%D7%99&type=phone_number&app_absent=0";
    setIsOpen(false);
  };

  if (type === 2) {
    return (
      <div
        className={`${styles.container} ${styles.primary}`}
        style={{
          backgroundImage: "url(/assets/images/subCardBG.png)",
        }}
      >
          <h3
          className={styles.plan}
          style={{
            bottom: "185px",
          }}
        >
          מוסך/פחח/טרייד/שמאי<br />

        </h3>
        <p className={styles.duration} style={{textAlign:'center'}}> 
        קבל מאיתנו חודש נוסף ללא עלות!!<br />
        להטבה שלח הודעה או בטלפון <br />
        <a style={{color:'white'}} href="tel:097467554"> 09-7467554</a>
        </p>
        <button className={styles.btn} onClick={handleButtonClick}>
         צור קשר בהודעה
        </button>
      </div>
    );
  }
  return (
    <div
      className={`${styles.container} ${classname}`}
      style={{
        backgroundImage:
          variant === "primary" ? "url(/assets/images/subCardBG.png)" : "",
      }}
    >
      <h3 className={styles.plan}>{plan}</h3>
      {/* {text && <p className={styles.text}>{text}</p>} */}
      <h2 className={styles.price}>
        <span>₪</span>
        {price}
      </h2>
      <p className={styles.duration}>{duration}</p>
      <br />
      <button
        className={styles.btn}
        onClick={() => {
          setPlanID(id);
          setIsSubModalOpen(true);
        }}
      >
        בחירה
      </button>
    </div>
  );
};

export default SubscribeCard;

import React, { useState, useEffect  }from 'react';
import { toast } from "react-toastify";
import CONTACT from "../api/services/CONTACT";
import Spinner from "../components/Spinner";
import ContactCard from "../features/contact/ContactCard";
import Layout from "../layouts/Layout";
import styles from "../styles/_pages/contact.module.scss";


const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendMsg = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const payload = {
        name,
        email,
        phone,
        message,
      };

      const res = await CONTACT.sendMessage(payload);
      toast.success(res.data.message);
    } catch (error) {
      //console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h2>יצירת קשר</h2>
          <p>מלאו את הפרטים ונציג מטעמינו יחזור אליכם בהקדם</p>

          <form onSubmit={sendMsg}>
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="שם מלא"
            />
            <input
              type="text"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="טלפון"
            />
            <input
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="אימייל"
            />
            <textarea
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="תוכן ההודעה"
            ></textarea>
            <button disabled={!name || !phone}>
              {loading ? <Spinner /> : "צרו איתנו קשר"}
            </button>
          </form>

          <div className={styles.cards}>
            <ContactCard
              icon="/assets/icons/location.svg"
              title="כתובת המשרד"
              link=""
              text="אזור תעשייה מעין צבי"
            />
            <ContactCard
              icon="/assets/icons/email.svg"
              title="כתובת אימייל"
              link="mailto:getpart4@gmail.com"
              text="getpart4@gmail.com"
            />
            <ContactCard
              icon="/assets/icons/phone.svg"
              title="טלפון / פקס המשרד"
              link="tel:097467557"
              text="09-7467557"
            />
            <ContactCard
              icon="/assets/icons/whatsapp.svg"
              title="לווצאפ עסקי"
              link="https://api.whatsapp.com/send?phone=972528098805"
              text="0528098805"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;

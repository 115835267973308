import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import styles from "../styles/_layouts/footer.module.scss";

const Footer = () => {
  let token = localStorage.getItem("authToken");

  const navigate = useNavigate();

  return (
    <div className="row-content">

    <footer className={styles.footer}>
      <div className={styles.top}>
        {/* RIGHT */}
        <div className={styles.right}>
          <img src="/assets/images/main_img_4.png" alt="graphic" />
        </div>

        {/* MIDDLE */}
        <div className={styles.middle}>
          <img
            src="/assets/icons/logo_8.svg"
            alt="logo"
            onClick={() => navigate(paths.main)}
            className={styles.logo}
          />
          <nav>
            <ul>
              <li onClick={() => navigate(paths.main)}>ראשי</li>
              {/* <li>אודותינו</li>
              <li>השירותים שלנו</li> */}
              <li onClick={() => navigate(paths.contact)}>צרו קשר</li>
              <li onClick={() => navigate(paths.regulations)}>תקנון אתר</li>
            </ul>
          </nav>
          {!token && (
            <div className={styles.btns}>
              <button onClick={() => navigate(paths.login)}>
                <img src="/assets/icons/user_square.svg" alt="login" />
                התחברות למערכת
              </button>
              <button onClick={() => navigate(paths.register)}>
                <img src="/assets/icons/doc.svg" alt="signup" />
                הרשמה למערכת
              </button>
            </div>
          )}
                  <div
          style={{
            marginTop: "35px",
            borderTopWidth: "1px",
            borderTopColor: "#ededed",
            borderTopStyle: "solid",
          }}
        >
          <h3
            style={{
              textAlign: "center",
              padding: "10px",
              fontSize: "16px",
            }}
          >
            הורידו את האפליקציה שלנו לחוויה מקסימלית!
          </h3>
          <div
            style={{
              marginTop: "5px",
              display:'flex',
              flexWrap:'wrap',
              gap:'10px',
              justifyContent:'center'
            }}
          >
            <a href="https://apps.apple.com/il/app/get-part/id6443868231">
              <img src="/assets/images/AppStore.svg" alt="appstore" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.gettparts.app">
              <img src="/assets/images/GooglePlay.svg" alt="google_play" />
            </a>
          </div>
        </div>
        </div>

        {/* LEFT */}
        <div className={styles.left}>
          <img src="/assets/images/main_img_5.png" alt="graphic" />
        </div>
      </div>

      <div className={styles.bottom}>
        <p>כל הזכויות שמורות ל Get Part</p>
        <a href="https://weable.co.il/"><svg xmlns="http://www.w3.org/2000/svg" width="137.682" height="32" viewBox="0 0 137.682 36">
          <g id="weable_logo" transform="translate(-176.131 -221.452)">
            <path id="w-yellow" d="M190.717,230.063l.6,10.655h.108l4.333-10.655h6.8l1.042,10.655h.11l3.894-10.655h8.062l-10.146,23.5h-6.472l-1.48-13.161h-.11L192.2,253.566H176.131v-23.5Z" transform="translate(0 -0.586)" fill="#ffde00"></path>
            <path id="l-w" d="M193.831,232.493l.545,9.628h.1l3.915-9.628h6.145l.942,9.628h.1l3.518-9.628h7.286l-9.168,21.238h-5.848l-1.338-11.892h-.1l-4.758,11.892H189.32l-2.775-21.238Z" transform="translate(-0.709 -0.751)"></path>
            <path id="l-e" d="M234.62,240.674a19.676,19.676,0,0,1-.445,3.87H223.767c0,.331-.049.661-.049,1.037,0,2.55,1.14,3.871,3.419,3.871a10.594,10.594,0,0,0,6.145-1.794l-.693,4.437a15.907,15.907,0,0,1-7.781,2.076c-6.443,0-8.425-4.248-8.425-8.684,0-7.647,3.964-13.452,10.457-13.452C232.489,232.037,234.62,235.577,234.62,240.674Zm-6.689.566a15.8,15.8,0,0,0,.148-2.312c0-1.793-.545-2.454-1.437-2.454-1.09,0-2.082,1.321-2.478,4.766Z" transform="translate(-2.74 -0.72)"></path>
            <path id="l-a" d="M254.811,253.7h-7.087l.249-1.793a6.638,6.638,0,0,1-4.659,2.076c-3.668,0-6.344-3.3-6.344-8.684,0-7.127,4.807-13.262,12.241-13.262a41.828,41.828,0,0,1,8.623.944Zm-8.673-5.143a3.145,3.145,0,0,0,2.527-1.369l1.388-9.581a4.754,4.754,0,0,0-1.388-.236c-2.379,0-4.262,3.586-4.262,7.74C244.4,247.376,245.048,248.557,246.138,248.557Z" transform="translate(-4.141 -0.72)"></path>
            <path id="l-b" d="M267.564,253.358a49.356,49.356,0,0,1-7.88-.756l4.608-31.15h7.137l-1.685,11.327h.1a7.33,7.33,0,0,1,4.361-1.462c3.865,0,6.392,3.4,6.392,8.779C280.6,248.5,276.187,253.358,267.564,253.358Zm-.1-5.333a4.838,4.838,0,0,0,1.289.142c2.379,0,4.411-2.974,4.411-7.647,0-2.36-.644-3.822-2.18-3.822A2.426,2.426,0,0,0,269,237.783Z" transform="translate(-5.687 0)"></path>
            <path id="l-l" d="M294.565,221.452l-4.659,31.527H282.77l4.658-31.527Z" transform="translate(-7.258 0)"></path>
            <path id="l-e1" d="M312.661,240.674a19.681,19.681,0,0,1-.445,3.87H301.808c0,.331-.049.661-.049,1.037,0,2.55,1.14,3.871,3.419,3.871a10.594,10.594,0,0,0,6.145-1.794l-.693,4.437a15.907,15.907,0,0,1-7.781,2.076c-6.443,0-8.425-4.248-8.425-8.684,0-7.647,3.964-13.452,10.457-13.452C310.53,232.037,312.661,235.577,312.661,240.674Zm-6.69.566a15.8,15.8,0,0,0,.148-2.312c0-1.793-.545-2.454-1.437-2.454-1.09,0-2.082,1.321-2.478,4.766Z" transform="translate(-8.051 -0.72)"></path>
            <path id="l-dot" d="M318.885,255.1a4.366,4.366,0,1,1,0-8.731,4.366,4.366,0,0,1,0,8.731Z" transform="translate(-9.414 -1.696)"></path>
          </g>
        </svg></a>
        {/* <img src="/assets/icons/logo_3.svg" alt="weable" /> */}
      </div>
    </footer>
    </div>
  );
};

export default Footer;

import React from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import { format } from "date-fns";

// Utility function for formatting dates
const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return format(date, "dd/MM/yyyy");
};

const primaryResources = [
  {
    rid: "053cea08-09bc-40ec-8f7a-156f0677aff3",
    filters: ({ text }) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
    secondaryResourcePayloadKeys: ["tozeret_cd", "degem_nm", "shnat_yitzur"],
  },
  {
    rid: "0866573c-40cd-4ca8-91d2-9dd2d7a492e5",
    filters: ({ text }) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
    secondaryResourcePayloadKeys: ["tozeret_cd", "degem_cd", "sug_degem"],
  },
  {
    rid: "03adc637-b6fe-402b-9937-7c3d3afc9140",
    filters: ({ text }) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
    secondaryResourcePayloadKeys: ["tozeret_cd", "degem_nm", "shnat_yitzur"],
  },
  {
    rid: "f6efe89a-fb3d-43a4-bb61-9bf12a9b9099",
    filters: ({ text }) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
    secondaryResourcePayloadKeys: ["tozeret_cd", "degem_nm", "shnat_yitzur"],
  },
  {
    rid: "6f6acd03-f351-4a8f-8ecf-df792f4f573a",
    filters: ({ text }) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "CAR",
    secondaryResourcePayloadKeys: ["tozeret_cd", "degem_nm", "shnat_yitzur"],
  },
  {
    rid: "bf9df4e2-d90d-4c0a-a400-19e15af8e95f",
    filters: ({ text }) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "BIKE",
  },
  {
    rid: "cd3acc5c-03c3-4c89-9c54-d40f93c0d790",
    filters: ({ text }) => ({
      mispar_rechev: text,
    }),
    secondaryApiTag: "TRUCK",
    secondaryResourcePayloadKeys: ["tozeret_cd", "degem_nm", "shnat_yitzur"],
  },
];

const secondaryResources = [
  {
    rid: "142afde2-6228-49f9-8a29-9b6c3a0cbe40",
    filters: (filters) => filters,
    tags: ["CAR"],
  },
];

const searchLicenseFromBackup = async (licenseNumber) => {
  try {
    const res = await axios.post(`${BASE_URL}/customer/licenseDetails`, {
      licenseNumber,
    });
    return {
      success: !!res?.data?.result,
      licenseData: res?.data?.result?.data,
      secondaryApiTag: res?.data?.result?.vehicle,
    };
  } catch (error) {
    console.log(`Error fetching data from backup API:`, error);
    return {
      success: false,
      licenseData: null,
      secondaryApiTag: null,
    };
  }
};

export const fieldNames = {
  mispar_rechev: "מספר רכב",

  tozeret_nm: "יצרן",
  kinuy_mishari: "דגם",
  shnat_yitzur: "שנת יצור",

  sug_delek_nm: "סוג דלק",
  nefah_manoa: "נפח מנוע",
  nefach_manoa: "נפח מנוע",
  mispar_dlatot: "מספר דלתות",
  degem_manoa: "דגם מנוע",
  moed_aliya_lakvish: "מועד עלייה לכביש",
  ramat_gimur: "רמת גימור",

  degem_cd: "קוד דגם",
  degem_nm: "שם דגם",
  tozeret_cd: "קוד יצרן",
  sug_degem: "סוג דגם",
  ramat_eivzur_betihuty: "רמת אבזור בטיחותי",
  kvutzat_zihum: "קבוצת זיהום",
  mivchan_acharon_dt: "טסט אחרון",
  tokef_dt: "תוקף רישיון רכב",
  baalut: "בעלות",
  misgeret: "מספר שילדה",
  tzeva_cd: "קוד צבע",
  tzeva_rechev: "צבע רכב",
  zmig_kidmi: "צמיג קדמי",
  zmig_ahori: "צמיג אחורי",
  horaat_rishum: "הוראת רישום",
  hanaa_nm: "הנעה",
  delek_nm: "דלק",
  mazgan_ind: "מזגן",
  technologiat_hanaa_nm: "טכנולוגית הנעה",
  kosher_grira_bli_blamim: "כושר גלילה בלי בלמים",
  kvuzat_agra_cd: "קבוצת אגרה",
  merkav: "מרכב",
  // automatic_ind: 'תיבת הילוכים',
  mispar_moshavim: "מספר מושבים",
  mishkal_kolel: "משקל",
  koah_sus: "כוס סוס",
  abs_ind: "ABS",
  kosher_grira_im_blamim: "כושר גרירה עם בלמים",
};

export const engineType = {
  ["בנזין"]: "GASOLINE",
  ["טורבו בנזין"]: "TURBO GASOLINE",
  ["דיזל"]: "DIESEL",
  ["טורבו דיזל"]: "TURBO DIESEL",
  ["היברידי"]: "HYBRID",
  ["חשמל"]: "ELECTRICAL POWER",
  ["חשמל/בנזין"]: "ELECTRICITY/GASOLINE",
  ['גפ"מ']: "GAPAM",
};

export const gearType = {
  ["0"]: "MANUAL",
  ["1"]: "AUTOMATIC",
  ["2"]: "TIPTONIC",
  ["3"]: "ROBOTIC",
};

const searchLicense = async ({ rid, type = "GENERAL", filters }) => {
  let baseUrl = "";
  if (type === "TRUCK") {
    baseUrl = `https://data.gov.il/api/3/action/datastore_search?resource_id=${rid}&filters=${JSON.stringify(
      filters
    )}`;
  } else {
    baseUrl = `https://data.gov.il/api/action/datastore_search?resource_id=${rid}&filters=${JSON.stringify(
      filters
    )}`;
  }

  // api call
  try {
    const res = await axios.get(baseUrl);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const useLicenseSearch = () => {
  const [loading, setLoading] = React.useState(false);
  const licenseExtraDataRef = React.useRef({});

  const searchLicenseByResourceId = React.useCallback(
    async (
      resourcesArray,
      { rid, filters, secondaryApiTag, secondaryResourcePayloadKeys },
      text,
      index
    ) => {
      let res = await searchLicense({
        rid,
        filters,
      });
      if (
        !(res && res.data && res.data.result && res.data.result.records.length)
      ) {
        if (index === resourcesArray.length - 1) {
          return {
            licenseData: null,
            secondaryApiTag: null,
            secondaryResourcePayloadKeys: null,
          };
        } else {
          return await searchLicenseByResourceId(
            resourcesArray,
            {
              rid: resourcesArray[index + 1].rid,
              filters: resourcesArray[index + 1].filters({ text }),
              secondaryApiTag: resourcesArray[index + 1].secondaryApiTag,
              secondaryResourcePayloadKeys:
                resourcesArray[index + 1].secondaryResourcePayloadKeys,
            },
            text,
            index + 1
          );
        }
      } else {
        return {
          licenseData: res?.data?.result?.records?.[0],
          secondaryApiTag,
          secondaryResourcePayloadKeys,
        };
      }
    },
    []
  );

  const searchLicenseExtraDataByResourceId = React.useCallback(
    async (resourcesArray, { rid, filters }, index, licenseData) => {
      let res = await searchLicense({
        rid,
        filters,
      });
      if (
        res &&
        res.data &&
        res.data.result &&
        res.data.result.records.length
      ) {
        licenseExtraDataRef.current = {
          ...licenseExtraDataRef.current,
          ...res.data.result.records[0],
        };
      }
      if (index === resourcesArray.length - 1) {
        return licenseExtraDataRef.current;
      } else {
        return await searchLicenseExtraDataByResourceId(
          resourcesArray,
          {
            rid: resourcesArray[index + 1].rid,
            filters,
          },
          index + 1,
          licenseData
        );
      }
    },
    []
  );

  const searchForLicense = React.useCallback(
    async (text) => {
      try {
        setLoading(true);

        // if (!text.trim().length && fromSearchForm) {
        //   setLicenseData({});
        //   setFields?.(initalFields);
        //   setDisableAllFields?.(false);
        //   return;
        // }
        // if (text.trim().length < 5 && fromSearchForm) {
        //   return;
        // }
        licenseExtraDataRef.current = {};
        const response = await searchLicenseByResourceId(
          primaryResources,
          {
            rid: primaryResources[0].rid,
            filters: primaryResources[0].filters({ text }),
            secondaryApiTag: primaryResources[0].secondaryApiTag,
            secondaryResourcePayloadKeys:
              primaryResources[0].secondaryResourcePayloadKeys,
          },
          text,
          0
        );
        if (response.licenseData) {
          const filteredSecondaryApis = secondaryResources.filter((i) =>
            i.tags.includes(response.secondaryApiTag)
          );
          if (filteredSecondaryApis.length) {
            const filtersPayload = response.secondaryResourcePayloadKeys.reduce(
              (a, i) => {
                a[i] = (response.licenseData[i] || "").toString();
                return a;
              },
              {}
            );
            await searchLicenseExtraDataByResourceId(
              filteredSecondaryApis,
              {
                rid: filteredSecondaryApis[0].rid,
                filters: filteredSecondaryApis[0].filters(filtersPayload),
              },
              0,
              response.licenseData
            );
          }
          const licenseData = {
            ...licenseExtraDataRef.current,
            ...response.licenseData,
          };
          const eType = licenseData?.sug_delek_nm || "";
          const gType =
            response.secondaryApiTag === "CAR" && licenseData.degem_cd
              ? String(licenseData?.automatic_ind || "")
              : "";
          console.log("licenseData", licenseData);
          // Format date fields
          const formattedLicenseData = {
            ...licenseData,
            mivchan_acharon_dt: formatDate(licenseData.mivchan_acharon_dt),
            tokef_dt: formatDate(licenseData.tokef_dt),
            moed_aliya_lakvish: formatDate(licenseData.moed_aliya_lakvish),
          };
          return {
            success: true,
            message: "",
            data: {
              ...formattedLicenseData,
              ...(response.secondaryApiTag !== "CAR" && {
                nefah_manoa: "",
                automatic_ind: "",
              }),
            },
            localData: {
              manufacturer: licenseData?.tozeret_nm || "לא צוין",
              model:
                response.secondaryApiTag === "CAR"
                  ? licenseData?.kinuy_mishari ||
                    licenseData?.degem_nm ||
                    "לא צוין"
                  : licenseData?.degem_nm || "",
              subModel: licenseData?.ramat_gimur || licenseData?.degem_nm || "",
              licenseNumber: text.trim(),
              yop: String(licenseData?.shnat_yitzur || ""),
              hp:
                response.secondaryApiTag === "CAR"
                  ? String(
                      licenseExtraDataRef.current?.nefah_manoa ||
                        licenseData.nefach_manoa ||
                        ""
                    )
                  : licenseData?.nefach_manoa || "",
              engineType: engineType[eType] ? engineType[eType] : "",
              gearType: gearType[gType] ? gearType[gType] : "",
              vehicle: response.secondaryApiTag,
            },
          };
        } else {
          const serverResponse = await searchLicenseFromBackup(text);
          if (serverResponse.success) {
            const response = serverResponse;
            // Format date fields from backup response
            const formattedLicenseData = {
              ...response.licenseData,
              mivchan_acharon_dt: formatDate(
                response.licenseData.mivchan_acharon_dt
              ),
              tokef_dt: formatDate(response.licenseData.tokef_dt),
              moed_aliya_lakvish: formatDate(
                response.licenseData.moed_aliya_lakvish
              ),
            };
            return {
              success: true,
              message: "",
              data: formattedLicenseData,
              localData: {
                manufacturer: response.licenseData?.tozeret_nm || "לא צוין",
                model:
                  response.secondaryApiTag === "CAR"
                    ? response.licenseData?.kinuy_mishari ||
                      response.licenseData?.degem_nm ||
                      "לא צוין"
                    : response.licenseData?.degem_nm || "",
                subModel:
                  response.licenseData?.ramat_gimur ||
                  response.licenseData?.degem_nm ||
                  "",
                licenseNumber: text.trim(),
                yop: String(response.licenseData?.shnat_yitzur || ""),
                hp:
                  response.secondaryApiTag === "CAR"
                    ? String(
                        response.licenseData?.nefah_manoa ||
                          response.licenseData.nefach_manoa ||
                          ""
                      )
                    : response.licenseData?.nefach_manoa || "",
                engineType:
                  engineType[response.licenseData?.sug_delek_nm || ""] || "",
                gearType:
                  gearType[
                    response.secondaryApiTag === "CAR" &&
                    response.licenseData.degem_cd
                      ? String(response.licenseData?.automatic_ind || "")
                      : ""
                  ] || "",
                vehicle: response.secondaryApiTag,
              },
            };
          } else {
            return {
              success: false,
              data: {},
              localdata: {},
            };
          }
        }
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [searchLicenseByResourceId, setLoading, searchLicenseExtraDataByResourceId]
  );
  return {
    searchForLicense,
    loading,
  };
};

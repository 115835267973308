import AboutCard from "../../features/main/AboutCard";
import FindCard from "../../features/main/FindCard";
import styles from "../../styles/_sections/main/hero.module.scss";

const Hero = ({ setIsOpen, dynamicContent }) => {
  return (
    <section className={styles.container}>
      <img
        src="/assets/images/hero_bg.png"
        alt="background"
        className={styles.bg}
      />

      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <img src="/assets/icons/logo_9.svg" alt="logo" />
          <p>{dynamicContent.promoText}</p>
        </div>

        <div className={styles.cards}>
          <FindCard setIsOpen={setIsOpen} dynamicContent={dynamicContent} />
          <AboutCard dynamicContent={dynamicContent} />
        </div>

        <div className={styles.steps}>
          <h3>מה השלבים למציאת חלקים?</h3>
          <p>
            בעזרת כמה שלבים פשוטים תמצאו כל חלק שתצטרכו לרכב שלכם אצל מגוון רחב
            של ספקים בפריסה ארצית
          </p>
          {/* <div className={styles.btns}>
            <a href="https://apps.apple.com/il/app/get-part/id6443868231"><img src="/assets/images/AppStore.svg" alt="appstore" /></a>
            <a href="https://play.google.com/store/apps/details?id=com.gettparts.app"><img src="/assets/images/GooglePlay.svg" alt="google_play" /></a>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default Hero;

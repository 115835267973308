import React, { useState, useEffect, useContext } from "react";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import SUBSCRIBER from "../api/services/SUBSCRIBER";
import MANUFACTURERS from "../api/services/MANUFACTURERS";
import Layout from "../layouts/Layout";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import { SocketContext } from "../App";
import Loader from "../components/Loader";
import { isTokenExpired } from "../utils/isTokenExpired";
import LicenseModal from "../components/LicenseModal";
import { toast } from "react-toastify";
import styles from "../styles/_pages/subscriberRequests.module.scss";
import { IMAGE_BASE_URL } from "../config";

const LatestSubscriberRequests = () => {
  let token = localStorage.getItem("authToken");
  const navigate = useNavigate();

  // get socket
  const socket = useContext(SocketContext);

  let userType = localStorage.getItem("user_type");

  // REDIRECT TO LOGIN IF NO TOKEN
  useEffect(() => {
    if (!token) {
      navigate(paths.login);
    }
  }, [token]);

  // IF THE USER IS SUPPLIER THEN NAVIGATE HIM FROM THIS SCREEN
  useEffect(() => {
    if (JSON.parse(userType) !== "SUBSCRIBER") {
      navigate(paths.supplierHistory);
    }
  }, [userType]);

  const [startDate, setStartDate] = useState();

  const [endDate, setEndDate] = useState();

  const [manufacturers, setManufacturers] = useState();

  const [manufacturer, setManufacturer] = useState(null);

  const [requests, setRequests] = useState();

  const [requestsCopy, setRequestsCopy] = useState();

  const [request, setRequest] = useState();

  const [selectedRequests, setSelectedRequests] = useState([]);

  const [imagePopup, setImagePopup] = useState(null);

  const [licensePopup, setLicensePopup] = useState(null);

  // FETCH REQUESTS
  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const res = await SUBSCRIBER.subscriber_requests();
        if (res.result.length > 0) {
          let filtered = res.result.filter((x) => !x.isArchivedBySubscriber);

          if (filtered.length > 10) {
            const top10 = filtered.slice(0, 10);
            setRequests(top10);
            setRequestsCopy(top10);
          } else {
            setRequests(filtered);
            setRequestsCopy(filtered);
          }
        } else {
          setRequests([]);
          setRequestsCopy([]);
        }
      } catch (error) {
        //console.log(error);
        setRequests([]);
        setRequestsCopy([]);
        isTokenExpired(error.response.data.status, navigate);
      }
    };

    fetchRequests();
  }, []);

  // FETCH MANUFACTURERS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await MANUFACTURERS.get();

        if (res.result.length === 0) {
          setManufacturers([]);
        } else {
          setManufacturers(res.result.manufacturers);
        }
      } catch (error) {
        //console.log(error);
      }
    };

    fetchData();
  }, []);

  // FILTER BY MANUFACTURER
  useEffect(() => {
    if (manufacturer) {
      if (requests) {
        if (requests.length > 0) {
          let filtered = requests.filter(
            (i) => i.manufacturer === manufacturer
          );
          setRequestsCopy(filtered);
        }
      }
    }
  }, [manufacturer, requests]);

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    if (requests) {
      if (requests.length > 0) {
        let filtered = requests.filter(
          (i) =>
            format(new Date(i.createdAt), "dd,MM,yyyy") >
              format(new Date(start), "dd,MM,yyyy") &&
            format(new Date(i.createdAt), "dd,MM,yyyy") <
              format(new Date(end), "dd,MM,yyyy")
        );
        setRequestsCopy(filtered);
      }
    }
  };

  // HANDLE CHECKBOX
  const handleCheckBox = (id) => {
    if (selectedRequests.find((i) => i._id === id)) {
      setSelectedRequests(selectedRequests.filter((j) => j._id !== id));
    } else {
      setSelectedRequests((prev) => [...prev, id]);
    }
  };

  // MOVE TO ARCHIVE
  const moveToArchive = async (id) => {
    const payload = { id, status: true };

    try {
      const res = await SUBSCRIBER.move_to_archive(payload);
      toast.success(res.data.message);
      navigate(paths.subscriberArchive);
    } catch (error) {
      //console.log(error);
      toast.error(error.response.data.message);
    }
  };

  // SOCKET LISTENER
  useEffect(() => {
    socket.on("subscriberRequestReply", (res) => {
      setRequestsCopy((prev) => {
        const newState = prev.map((i) => {
          if (i._id === res.reply.request) {
            return { ...i, supplierReplies: [...i.supplierReplies, res.reply] };
          }

          return i;
        });

        return newState;
      });
    });

    return () => {
      socket.off("subscriberRequestReply");
    };
  });

  // print requests
  const printRequests = () => {
    ////console.log('print');
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* CARD */}
          <div className={styles.card}>
            <h2>החיפושים שלי</h2>

            {/* BUTTON ----------- */}
            {/* <button className={styles.btn1}>
              <div>מספר הרכב שלך</div>
            </button> */}

            {/* SEARCH FIELDS ----------- */}
            <div className={styles.search}>
              {/* SEARCH BY MANUFACTURER */}
              <div className={styles.field}>
                <label>חיפוש יצרן</label>
                <select
                  name="manufacturer"
                  value={manufacturer}
                  onChange={(e) => {
                    setManufacturer(e.target.value);
                  }}
                >
                  <option value="" disabled selected>
                    בחר יצרן
                  </option>
                  {manufacturers &&
                    manufacturers.map((i) => (
                      <option key={i.id} value={i.name}>
                        {i.name}
                      </option>
                    ))}
                </select>
              </div>

              {/* SEARCH BY DATE - RANGE */}
              <div className={styles.field}>
                <label>חיפוש מתאריך</label>
                <div className={styles.datePicker}>
                  <img src="/assets/icons/calendar.svg" alt="calendar" />
                  <DatePicker
                    selected={startDate}
                    onChange={onDateChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    placeholderText={format(new Date(), "dd.MM.yyyy")}
                    // inline
                  />
                </div>
              </div>
            </div>

            {/* TABLE ----------- */}
            <div className={styles.tableWrapper}>
              {!requestsCopy ? (
                <Loader />
              ) : (
                <div
                  style={{ overflowX: "auto", width: "100%" }}
                  id="printablediv"
                >
                  <table className={styles.mainTable}>
                    <thead>
                      <tr>
                        <th style={{ width: "70px" }}></th>
                        {/* <th>מספר בקשה</th> */}
                        <th>יצרן</th>
                        <th>מס' רכב</th>
                        <th>חלק</th>
                        <th>תאריך בקשה</th>
                        <th>דגם</th>
                        <th>שנת יצור</th>
                        <th>תשובה מספקים</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {requestsCopy.map((i, idx) => (
                        <React.Fragment key={i._id}>
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedRequests.find(
                                  (x) => x._id === i._id
                                )}
                                onClick={() => handleCheckBox(i._id)}
                              />
                            </td>
                            {/* req id */}
                            {/* <td>{i._id}</td> */}
                            {/* manufacturer */}
                            <td>{i.manufacturer}</td>
                            {/* vehicle no */}
                            <td
                              className={styles.license}
                              onClick={() => {
                                if (i.carLicenseNumber) {
                                  setLicensePopup(i.carLicenseNumber);
                                }
                              }}
                            >
                              {i.carLicenseNumber ? i.carLicenseNumber : "-"}
                            </td>
                            {/* part name */}
                            <td className={styles.part}>
                              {!i.customPartName ? (
                                <div className={styles.part}>
                                  {i.images && i.images.length > 0
                                    ? i.images.map((i) => (
                                        <img
                                          src={`${IMAGE_BASE_URL}${i}`}
                                          alt="part"
                                          onClick={() =>
                                            setImagePopup(
                                              `${IMAGE_BASE_URL}${i}`
                                            )
                                          }
                                        />
                                      ))
                                    : null}
                                  {!i.isVehicle && i.part && i.part.name
                                    ? i.part.name
                                    : "רכב שלם"}
                                </div>
                              ) : (
                                <div className={styles.part}>
                                  {i.customPartName}
                                </div>
                              )}
                            </td>
                            {/* date */}
                            <td>
                              {format(new Date(i.createdAt), "dd.MM.yyyy")}
                            </td>
                            {/* model */}
                            <td>{i.model ? i.model : "-"}</td>
                            {/* yop */}
                            <td>{i.yop ? i.yop : "-"}</td>
                            {/* replies */}
                            <td>
                              {i.supplierReplies && i.supplierReplies.length > 0
                                ? `ענו ${i.supplierReplies.length} ספקים`
                                : "-"}
                            </td>

                            {/* move to archive */}
                            <td>
                              <button onClick={() => moveToArchive(i._id)}>
                                העבר לארכיון
                              </button>
                            </td>

                            {/* open dropdown */}
                            <td onClick={() => setRequest(i._id)}>
                              <div>
                                <img
                                  src="/assets/icons/share.svg"
                                  alt="results"
                                />
                                הצג תוצאות
                              </div>
                            </td>
                          </tr>
                          {i._id === request && (
                            <tr className={styles.collapsedRow}>
                              <td colspan={10}>
                                <table className={styles.collapsedTable}>
                                  <thead>
                                    <th style={{ width: "70px" }}></th>
                                    {/* vehicle no */}
                                    <th>מס' רכב</th>
                                    {/* part name */}
                                    <th>חלק</th>
                                    {/* supplier name */}
                                    <th>ספק</th>
                                    {/* car serial id */}
                                    <th>מס' רשיון</th>
                                    {/* price */}
                                    <th>מחיר</th>
                                    {/* comment from supplier */}
                                    <th>הערה מספק</th>
                                    {/* supplier address */}
                                    <th>כתובת הספק</th>
                                    {/* contact */}
                                    <th>יצירת קשר</th>
                                  </thead>

                                  <tbody>
                                    {i.supplierReplies &&
                                      i.supplierReplies.map((j) => (
                                        <tr>
                                          <td></td>
                                          {/* vehicle no */}
                                          <td>
                                            {i.vehicleNumber
                                              ? i.vehicleNumber
                                              : "-"}
                                          </td>
                                          {/* part name */}
                                          <td>
                                            {i.part &&
                                              i.part.name &&
                                              i.part.name}
                                          </td>
                                          {/* supplier name */}
                                          <td>{j.supplier[0].name}</td>
                                          {/* car serial id */}
                                          <td>
                                            {i.carLicenseNumber
                                              ? i.carLicenseNumber
                                              : "-"}
                                          </td>
                                          {/* price */}
                                          <td>{j.price ? j.price : "-"}</td>
                                          {/* comment from supplier */}
                                          <td>{j.comment ? j.comment : "-"}</td>
                                          {/* supplier address */}
                                          <td>-</td>
                                          {/* contact */}
                                          <td>
                                            <div className={styles.contact}>
                                              <img
                                                src="/assets/icons/whatsapp_2.svg"
                                                alt="whatsapp"
                                              />
                                              04314312
                                            </div>
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            {/* BUTTONS */}
            <div className={styles.btnsContainer}>
              <button>סמן הכל</button>
              {/* <button>העבר לארכיון</button>
              <button>שלח במייל</button> */}
              <button onClick={printRequests}>הדפס</button>
            </div>
          </div>

          <div className={styles.cardsWrapper}>
            {/* CARDS */}
            {!requestsCopy ? (
              <Loader />
            ) : (
              <div className={styles.cards}>
                {requestsCopy.map((i, idx) => (
                  <>
                    <div
                      className={`${styles.reqCard} ${
                        i._id === request ? styles.collapsed : ""
                      }`}
                    >
                      <div className={styles.item}>
                        <h3>יצרן</h3>
                        <p>{i.manufacturer}</p>
                      </div>
                      <div className={styles.item}>
                        <h3>מס' רכב</h3>
                        <p
                          onClick={() => {
                            if (i.carLicenseNumber) {
                              setLicensePopup(i.carLicenseNumber);
                            }
                          }}
                        >
                          {i.carLicenseNumber ? i.carLicenseNumber : "-"}
                        </p>
                      </div>
                      <div className={styles.item}>
                        <h3>חלק</h3>
                        <p>
                          {!i.customPartName
                            ? !i.isVehicle && i.part && i.part.name
                              ? i.part.name
                              : "רכב שלם"
                            : i.customPartName}
                        </p>
                      </div>
                      <div className={styles.item}>
                        <h3>תאריך בקשה</h3>
                        <p>{format(new Date(i.createdAt), "dd.MM.yyyy")}</p>
                      </div>
                      <div className={styles.item}>
                        <h3>דגם</h3>
                        <p>{i.model ? i.model : "-"}</p>
                      </div>
                      <div className={styles.item}>
                        <h3>שנת יצור</h3>
                        <p>{i.yop ? i.yop : "-"}</p>
                      </div>
                      <div className={`${styles.item} ${styles.lastItem}`}>
                        <h3>תשובה מספקים</h3>
                        <p>
                          {i.supplierReplies && i.supplierReplies.length > 0
                            ? `ענו ${i.supplierReplies.length} ספקים`
                            : "-"}
                        </p>
                      </div>

                      {/* move to archive */}
                      <button
                        className={styles.btn}
                        onClick={() => moveToArchive(i._id)}
                      >
                        העבר לארכיון
                      </button>

                      {i.supplierReplies.length > 0 && (
                        <div
                          className={styles.results}
                          onClick={() => setRequest(i._id)}
                        >
                          <img src="/assets/icons/share.svg" alt="results" />
                          <p>הצג תוצאות</p>
                        </div>
                      )}
                    </div>

                    {i._id === request && (
                      <div className={styles.collapsedCards}>
                        {i.supplierReplies &&
                          i.supplierReplies.map((j) => (
                            <div className={styles.collapsedCard}>
                              <div className={styles.item}>
                                <h3>מס' רכב</h3>
                                <p>{i.vehicleNumber ? i.vehicleNumber : "-"}</p>
                              </div>
                              <div className={styles.item}>
                                <h3>חלק</h3>
                                <p>{i.part && i.part.name && i.part.name}</p>
                              </div>
                              <div className={styles.item}>
                                <h3>ספק</h3>
                                <p>{j.supplier[0].name}</p>
                              </div>
                              <div className={styles.item}>
                                <h3>מס' רשיון</h3>
                                <p>
                                  {i.carLicenseNumber
                                    ? i.carLicenseNumber
                                    : "-"}
                                </p>
                              </div>
                              <div className={styles.item}>
                                <h3>מחיר</h3>
                                <p>{j.price ? j.price : "-"}</p>
                              </div>
                              <div className={styles.item}>
                                <h3>הערה מספק</h3>
                                <p>{j.comment ? j.comment : "-"}</p>
                              </div>
                              <div className={styles.item}>
                                <h3>כתובת הספק</h3>
                                <p>-</p>
                              </div>
                              <div className={styles.item}>
                                <h3>יצירת קשר</h3>
                                <p>-</p>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
          </div>

          <div className={styles.btnsContainerMob}>
            <button>סמן הכל</button>
            <button onClick={printRequests}>הדפס</button>
          </div>
        </div>
        {/* PART IMAGE MODAL */}
        {imagePopup && (
          <div className={styles.popupContainer}>
            <div
              style={{
                height: "100vh",
                width: "100%",
                position: "fixed",
                left: "0px",
                top: "0px",
                opacity: 0.45,
                background: "#000000 0% 0% no-repeat padding-box",
              }}
              onClick={() => setImagePopup(null)}
            ></div>
            <div className={styles.popup}>
              <span onClick={() => setImagePopup(null)}>&times;</span>
              <img src={imagePopup} alt="part" />
            </div>
          </div>
        )}

        {/*  LICENSE NUMBER DETAILS MODAL */}
        {licensePopup && (
          <LicenseModal setIsOpen={setLicensePopup} licenseNum={licensePopup} />
        )}
      </div>
    </Layout>
  );
};

export default LatestSubscriberRequests;

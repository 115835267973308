import { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import UserDetailsModal from "../components/UserDetailsModal";
import styles from "../styles/_layouts/layout.module.scss";
import SideNav from "./SideNav";

const Layout = ({ children }) => {
  const [isUserModal, setIsUserModal] = useState(false);

  const [sideNav, setSideNav] = useState(false);
  return (
    <>
      <Header setIsOpen={setIsUserModal} setSideNav={setSideNav} />
      <main className={styles.main}>{children}</main>
      <Footer />
      {isUserModal && <UserDetailsModal setIsOpen={setIsUserModal} />}
      {sideNav && <SideNav setSideNav={setSideNav} />}
    </>
  );
};

export default Layout;

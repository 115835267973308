import { post } from "../client";
import { FORGOT_PASSWORD, FORGOT_VERIFY, LOGIN, REGISTER } from "../routes";

export default {
  login: async (data) => await post(LOGIN, data, false),

  register: async (data) => await post(REGISTER, data, false),

  forgot: async (data) => await post(FORGOT_PASSWORD, data, false),

  forgot_verify: async (data) => await post(FORGOT_VERIFY, data, false),
};

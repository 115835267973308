import styles from "../../styles/_features/contact/contactCard.module.scss";
import { Link } from 'react-router-dom';

const ContactCard = ({ icon, title, text, link }) => {
  return (
    <div className={styles.container}>
      <img
        src={icon}
        alt={title}
        style={{ top: title === "כתובת אימייל" ? "60px" : "50px" }}
      />
      <h3>{title}</h3>
      <a
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
        href={`${link}`}>
        <p>{text}</p>
      </a>
    </div>
  );
};

export default ContactCard;

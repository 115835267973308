import { useState } from "react";
import { useEffect } from "react";
import PARTS from "../../api/services/PARTS";
import styles from "../../styles/_features/createRequest/parts.module.scss";

const Parts = ({
  category,
  parts,
  setParts,
  duplicateArr,
  setDuplicateArr,
  part,
  setPart,
  selectedParts,
  setSelectedParts,
}) => {

  // FETCH PARTS
  useEffect(() => {
    if (category) {
      const fetchParts = async () => {
        try {
          const res = await PARTS.get(category);
          if (res.result.length > 0) {
            // create a copy of original array with additional properties
            let arr = res.result.map((i) => {
              let obj = {
                _id: i._id,
                name: i.name,
                desc: "",
                img: null,
                imgPreview: null,
              };
              return obj;
            });
            setDuplicateArr(arr);
            setParts(res.result);
          }
        } catch (error) {
          //console.log(error);
          setParts([]);
          setDuplicateArr([]);
        }
      };

      fetchParts();
    }
  }, [category]);


  // handle checkbox change
  const handleCheckChange = (id, index) => {
    if (selectedParts.find((j) => j._id === id)) {
      setSelectedParts(selectedParts.filter((x) => x._id !== id));
    } else {
      setSelectedParts((prev) => {
        return [...prev, duplicateArr[index]];
      });
    }
  };

  return (
    <div className={styles.container}>
      <div style={{ overflowX: "auto" }}>
        <table>
          <thead>
            <tr>
              <th>בחירת חלק</th>
              {/* <th>הערות לספק</th> */}
              <th></th>
            </tr>
          </thead>

          <tbody>
            {duplicateArr &&
              duplicateArr.map((i, idx) => (
                <tr key={i._id}>
                  <td>
                    <div className={styles.name}>
                      <input
                        type="checkbox"
                        name="partName"
                        className={styles.checkbox}
                        checked={selectedParts.find((j) => j._id === i._id)}
                        onChange={(e) => handleCheckChange(i._id, idx)}
                      />
                      <p>{i.name}</p>
                    </div>
                  </td>
                  {/* <td>
                    <input
                      type="text"
                      name={i._id}
                      className={styles.desc}
                      value={i._desc}
                      onChange={(e) => handleDescChange(e, idx)}
                    />
                  </td> */}
                  {/* {selectedImages.length > 0 &&
                  selectedImages.find((x) => x.idx === idx) ? (
                    <td>
                      <img
                        src={selectedImages.find((x) => x.idx === idx).img}
                        alt={i.name}
                        className={styles.imgPreview}
                      />
                    </td>
                  ) : (
                    <td>
                      <input
                        type="file"
                        name="partImg"
                        className={styles.imgInput}
                        onChange={(e) => handleImgChange(e, idx)}
                      />
                      <img src="/assets/icons/camera.svg" alt="camera" />
                    </td>
                  )} */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Parts;

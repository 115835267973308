import { useEffect } from "react";
import { useState } from "react";
import PAYMENT from "../api/services/PAYMENT";
import PaymentModal from "./PaymentModal";
import SubscribeCard from "./SubscribeCard";
import styles from "../styles/_components/modal.module.scss";

const style = {
  height: "100vh",
  width: "100%",
  position: "fixed",
  left: "0px",
  top: "0px",
  opacity: 0.45,
  background: "#000000 0% 0% no-repeat padding-box",
};

const Backdrop = ({ setIsOpen }) => (
  <div style={style} onClick={() => setIsOpen(false)}></div>
);

const Modal = ({ setIsOpen }) => {
  const [isSubModalOpen, setIsSubModalOpen] = useState(false);

  const [plans, setPlans] = useState();

  const [planID, setPlanID] = useState();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const res = await PAYMENT.all_plans();

        if (res?.result?.length > 0) {
          let filtered = res.result.filter((i) => i.type !== "MONTHLY");
          setPlans(filtered);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchPlans();
  }, []);

  return (
    <div className={styles.mainContainer}>
      <Backdrop setIsOpen={setIsOpen} />
      <div className={styles.container}>
        <span className={styles.close} onClick={() => setIsOpen(false)}>
          x
        </span>
        <div className={styles.content}>
          <h2 className={styles.heading1}>לקוח יקר</h2>
          <p className={styles.text}>ניצלת את החיפוש חינם לרכב אחד במערכת</p>
          <h3 className={styles.heading2}>
            בחר אחת מהחבילות על מנת להמשיך בחיפוש
          </h3>

          <div className={styles.cards}>
            {plans &&
              plans.map((i) => (
                <SubscribeCard
                  type={1}
                  id={i._id}
                  key={i._id}
                  plan={i.name}
                  text={i.name === "לייט" ? "" : "חיפוש ללא הגבלה"}
                  price={i.amount}
                  duration={i.description}
                  variant={i.name !== "לייט" ? "primary" : "secondary"}
                  setIsSubModalOpen={setIsSubModalOpen}
                  setPlanID={setPlanID}
                />
              ))}
            {/* type 2 is for static card */}
            <SubscribeCard type={2} setIsOpen={setIsOpen} />
          </div>
        </div>
        <span style={{ fontSize: "12px", textAlign: "center", zIndex: 9 }}>
          המחירים כוללים מע״מ
        </span>
        <span style={{ fontSize: "12px", textAlign: "center" }}>
          ניתן לבטל בכל עת
        </span>
      </div>

      {isSubModalOpen && (
        <PaymentModal
          setIsOpen={setIsSubModalOpen}
          setIsOpen2={setIsOpen}
          planID={planID}
        />
      )}
    </div>
  );
};

export default Modal;

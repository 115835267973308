import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import SUPPLIER from "../api/services/SUPPLIER";
import { SocketContext } from "../App";
import Loader from "../components/Loader";
import Spinner from "../components/Spinner";
import Layout from "../layouts/Layout";
import { paths } from "../routes/paths";
import { isTokenExpired } from "../utils/isTokenExpired";
import LicenseModal from "../components/LicenseModal";
import { IMAGE_BASE_URL } from "../config";
import styles from "../styles/_pages/supplierRequests.module.scss";

const LatestSupplierRequests = () => {
  const navigate = useNavigate();

  let token = localStorage.getItem("authToken");

  let userType = localStorage.getItem("user_type");

  // get socket
  const socket = useContext(SocketContext);

  // REDIRECT TO LOGIN IF NO TOKEN
  useEffect(() => {
    if (!token) {
      navigate(paths.login);
    }
  }, [token]);

  // IF THE USER IS NOT SUPPLIER THEN NAVIGATE HIM FROM THIS SCREEN
  useEffect(() => {
    if (JSON.parse(userType) !== "SUPPLIER") {
      navigate(paths.main);
    }
  }, [userType]);

  const [requests, setRequests] = useState();

  const [requestsCopy, setRequestsCopy] = useState();

  const [request, setRequest] = useState();

  const [selectedRequests, setSelectedRequests] = useState([]);

  const [priceVal, setPriceVal] = useState("");

  const [comment, setComment] = useState("");

  const [searchVal, setSearchVal] = useState("");

  const [deleteSuccess, setDeleteSuccess] = useState(null);

  const [deleteLoading, setDeleteLoading] = useState(false);

  const [approveLoading, setApproveLoading] = useState(false);

  const [approveWithPriceLoading, setApprovePriceLoading] = useState(false);

  const [commentLoading, setCommentLoading] = useState(false);

  const [imagePopup, setImagePopup] = useState(null);

  const [licensePopup, setLicensePopup] = useState(null);

  const [isToastShowing, setToastShowing] = useState(false);


  // FETCH REQUESTS
  useEffect(() => {
    setDeleteSuccess(null);
    const fetchRequests = async () => {
      try {
        const res = await SUPPLIER.supplier_requests();
        let filtered = res.result.filter((x) => !x.isArchivedBySupplier);

        if (filtered.length > 10) {
          const top10 = filtered.slice(0, 10);
          setRequests(top10);
          setRequestsCopy(top10);
        } else {
          setRequests(filtered);
          setRequestsCopy(filtered);
        }
      } catch (error) {
        //console.log(error);
        setRequests([]);
        setRequestsCopy([]);
        isTokenExpired(error.response.data.status, navigate);
      }
    };

    fetchRequests();
  }, [deleteSuccess]);

  // HANDLE CHECKBOX
  const handleCheckBox = (id) => {
    if (selectedRequests.find((i) => i._id === id)) {
      setSelectedRequests(selectedRequests.filter((j) => j._id !== id));
    } else {
      setSelectedRequests((prev) => [...prev, id]);
    }
  };

  // ACCEPT WITHOUT PRICE
  const acceptWithoutPrice = async (subID, requestID, comment) => {
    setApproveLoading(true);
    try {
      const payload = {
        subscriber: subID,
        request: requestID,
        status: "ACCEPT_WITHOUT_PRICE",
        comment: comment,
      };

      const res = await SUPPLIER.create_reply(payload);
      // toast.success(res.data.message);
    } catch (error) {
      //console.log(error);
      toast.error(error.response.data.message);
    }
    setApproveLoading(false);
  };

  // MOVE TO ARCHIVE
  const moveToArchive = async (id) => {
    const payload = { id, status: true };

    try {
      const res = await SUPPLIER.move_to_archive(payload);
      // toast.success(res.data.message);
      // navigate(paths.supplierArchive);
    } catch (error) {
      //console.log(error);
      toast.error(error.response.data.message);
    }
  };

  // ACCEPT WITH PRICE
  const acceptWithPrice = async (subID, requestID, price, comment) => {
    setApprovePriceLoading(true);
    try {
      const payload = {
        subscriber: subID,
        request: requestID,
        price,
        status: "ACCEPT_WITH_PRICE",
        comment,
      };

      const res = await SUPPLIER.create_reply(payload);
      // toast.success(res.data.message);
      moveToArchive(requestID);
    } catch (error) {
      //console.log(error);
      toast.error(error.response.data.message);
    }
    setApprovePriceLoading(false);
  };

  // DELETE REQUEST
  const deleteRequest = async (subID, requestID) => {
    setDeleteLoading(true);
    try {
      const payload = {
        subscriber: subID,
        request: requestID,
        status: "DELETE",
      };

      const res = await SUPPLIER.create_reply(payload);
      // toast.success(res.data.message);
      setDeleteSuccess(true);
      moveToArchive(requestID);
    } catch (error) {
      //console.log(error);
      toast.error(error.response.data.message);
    }
    setDeleteLoading(false);
  };

  // COMMENT
  const commentOnRequest = async (subID, requestID, comment) => {
    setCommentLoading(true);
    try {
      const payload = {
        subscriber: subID,
        request: requestID,
        status: "ACCEPT_WITHOUT_PRICE",
        comment: comment,
      };

      const res = await SUPPLIER.create_reply(payload);
      // toast.success(res.data.message);
    } catch (error) {
      //console.log(error);
      toast.error(error.response.data.message);
    }
    setCommentLoading(false);
  };

  // HANDLE SEARCH
  const handleSearch = (e) => {
    setSearchVal(e.target.value);
    let search = e.target.value;
    if (search.trim()) {
      if (requests) {
        if (requests.length > 0) {
          let filtered = requests.filter(
            (i) =>
              i.part.name.includes(search) ||
              i.subscriberId.name.includes(search)
          );
          setRequestsCopy(filtered);
        }
      }
    } else {
      if (requests) {
        if (requests.length > 0) {
          setRequestsCopy(requests);
        }
      }
    }
  };

  // SOCKET LISTENER
  useEffect(() => {
    socket.on("supplierPartRequest", (res) => {
      let supplier = res.supplier;
      let request = res.request;
      request.partObj = supplier.part;

      setRequestsCopy((prev) => [request, ...prev]);
    });

    return () => {
      socket.off("supplierPartRequest");
    };
  });

  return (
    <Layout>
      
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* CARD */}
          <div className={styles.card}>
            <h2>הבקשות שלי</h2>

            <div className={styles.search}>
              <input
                type="text"
                value={searchVal}
                onChange={handleSearch}
                placeholder="חיפוש"
              />
              {/* <button onClick={handleSearch}>חפש</button> */}
            </div>

            {/* TABLE ----------- */}
            {!requestsCopy ? (
              <Loader />
            ) : (
              <div style={{ overflowX: "auto", width: "100%" }}>
                <table className={styles.mainTable}>
                  <thead>
                    <tr>
                      {/* <th style={{ width: "70px" }}></th> */}
                      {/* Request id */}
                      {/* <th>מספר בקשה</th> */}
                      {/* Client Name */}
                      <th style={{ width: "180px", textAlign: "center" }}>
                        שם לקוח
                      </th>
                      {/* Manufacturer */}
                      <th>יצרן</th>
                      {/* Car license number */}
                      <th>מס' רכב</th>
                      {/* Part name */}
                      <th>חלק</th>
                      {/* search date  */}
                      <th>תאריך בקשה</th>
                      {/* car model */}
                      <th>דגם</th>
                      {/* Year of car production */}
                      <th>שנת יצור</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {requestsCopy.map((i, idx) => (
                      <>
                        <tr>
                          {/* <td>
                            <input
                              type="checkbox"
                              checked={selectedRequests.find(
                                (x) => x._id === i._id
                              )}
                              onClick={() => handleCheckBox(i._id)}
                            />
                          </td> */}
                          {/* client name */}
                          <td>{i.subscriberId.name}</td>
                          {/* manufacturer */}
                          <td>{i.manufacturer}</td>
                          {/* car license number */}
                          <td
                            className={styles.license}
                            onClick={() => {
                              if (i.carLicenseNumber) {
                                setLicensePopup(i.carLicenseNumber);
                              }
                            }}
                          >
                            {i.carLicenseNumber ? i.carLicenseNumber : "-"}
                          </td>
                          {/* part name */}
                          <td className={styles.part}>
                            {!i.customPartName ? (
                              <div className={styles.part}>
                                {i.images && i.images.length > 0
                                  ? i.images.map((i) => (
                                      <img
                                        src={`${IMAGE_BASE_URL}${i}`}
                                        alt="part"
                                        onClick={() =>
                                          setImagePopup(`${IMAGE_BASE_URL}${i}`)
                                        }
                                      />
                                    ))
                                  : null}
                                {!i.isVehicle && i.part && i.part.name
                                  ? i.part.name
                                  : "רכב שלם"}
                              </div>
                            ) : (
                              <div className={styles.part}>
                                {i.customPartName}
                              </div>
                            )}
                          </td>
                          {/* search date */}
                          <td>
                            {i.createdAt
                              ? format(new Date(i.createdAt), "dd.MM.yyyy")
                              : "-"}
                          </td>
                          {/* car model */}
                          <td>{i.model ? i.model : "-"}</td>
                          {/* yop */}
                          <td>{i.yop ? i.yop : "-"}</td>
                          <td style={{ cursor: "pointer" }}>
                            <img
                              src="/assets/icons/trash.svg"
                              alt="delete"
                              onClick={() =>
                                deleteRequest(i.subscriberId._id, i._id)
                              }
                            />
                          </td>
                          <td
                            onClick={() => {
                              setPriceVal("");
                              setRequest(i._id);
                            }}
                          >
                            <button>
                              {/* <img
                                src="/assets/icons/share.svg"
                                alt="results"
                              /> */}
                              הצג תוצאות
                            </button>
                          </td>
                        </tr>

                        {i._id === request && (
                          <tr className={styles.collapsedRow}>
                            {i.lastReply ? (
                              <td colspan={10}>
                                <div className={styles.already}>
                                  <p>כבר הגבת לבקשה זו</p>
                                </div>
                              </td>
                            ) : (
                              <td colspan={10}>
                                <div className={styles.boxes}>
                                  <div className={styles.offer}>
                                    <h6>שליחת הצעה עם תמחור</h6>
                                    <input
                                      type="text"
                                      placeholder="הכנס סכום"
                                      value={priceVal}
                                      onChange={(e) =>
                                        setPriceVal(e.target.value)
                                      }
                                    />
                                    <input
                                      type="text"
                                      placeholder="הערה"
                                      value={comment}
                                      onChange={(e) =>
                                        setComment(e.target.value)
                                      }
                                    />
                                    <button
                                      onClick={() =>
                                        acceptWithPrice(
                                          i.subscriberId._id,
                                          i._id,
                                          priceVal,
                                          comment
                                        )
                                      }
                                    >
                                      {approveWithPriceLoading ? (
                                        <Spinner />
                                      ) : (
                                        "שלח תמחור"
                                      )}
                                    </button>
                                  </div>

                                  <div className={styles.reject}>
                                    <button
                                      onClick={() =>
                                        deleteRequest(i.subscriberId._id, i._id)
                                      }
                                    >
                                      {deleteLoading ? (
                                        <Spinner />
                                      ) : (
                                        "מחיקת בקשה"
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </td>
                            )}
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        {/* PART IMAGE MODAL */}
        {imagePopup && (
          <div className={styles.popupContainer}>
            <div
              style={{
                height: "100vh",
                width: "100%",
                position: "fixed",
                left: "0px",
                top: "0px",
                opacity: 0.45,
                background: "#000000 0% 0% no-repeat padding-box",
              }}
              onClick={() => setImagePopup(null)}
            ></div>
            <div className={styles.popup}>
              <span onClick={() => setImagePopup(null)}>&times;</span>
              <img src={imagePopup} alt="part" />
            </div>
          </div>
        )}

        {/*  LICENSE NUMBER DETAILS MODAL */}
        {licensePopup && (
          <LicenseModal setIsOpen={setLicensePopup} licenseNum={licensePopup} />
        )}
      </div>
    </Layout>
  );
};

export default LatestSupplierRequests;

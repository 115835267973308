import { fieldNames } from "../hooks/useLicenseSearch";
import styles from "../styles/_components/licenseModal.module.scss";
import { format } from "date-fns";

const style = {
  height: "100vh",
  width: "100%",
  position: "fixed",
  left: "0px",
  top: "0px",
  opacity: 0.45,
  background: "#000000 0% 0% no-repeat padding-box",
};

const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return format(date, "dd/MM/yyyy");
};

const Backdrop = ({ setIsOpen }) => (
  <div style={style} onClick={() => setIsOpen(null)}></div>
);

// Utility function to remove leading zeros
const removeLeadingZeros = (value) => {
  if (typeof value === "string") {
    return value.replace(/^0+/, "");
  }
  return value;
};

const LicenseModal = ({ setIsOpen, licenseData }) => {
  const handlePrint = () => {
    const printableContent = document.getElementById("printableContent");

    if (printableContent) {
      const printWindow = window.open("", "", "width=600,height=600");
      printWindow.document.open();
      printWindow.document.write("<html><head><title>Print</title>");
      printWindow.document.write("<style>");

      // Add inline styles within the style tag
      printWindow.document.write(`
        body { font-size: 12px; direction: rtl; }
        .printable-content { margin: 20px; padding: 10px; border: 1px solid #000; }
        h1 { font-size: 20px; }
        ul { list-style-type: none; padding: 0; }
        li { margin: 2px 0; }
        li span { font-weight: bold; }

        div { width: 100%; height: 100%; display: flex; flex-direction: column; }
        ul { display: flex; flex-wrap: wrap; justify-content: space-between; }
        li { margin-bottom: -10px; list-style: none; width: 49%; display: flex; align-items: center; gap: 5px; }
        p:nth-child(1) { font-weight: bold; }
      `);

      printWindow.document.write("</style>");
      printWindow.document.write("</head><body>");

      printWindow.document.write(printableContent.innerHTML);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      // printWindow.close();
    } else {
      console.warn("Printable content not found.");
    }
  };

  console.log(licenseData, "============= LICENSE MODAL DATA ===============");

  return (
    <div className={styles.mainContainer}>
      <Backdrop setIsOpen={setIsOpen} />
      <div className={styles.container}>
        <span className={styles.close} onClick={() => setIsOpen(null)}>
          x
        </span>
        <button className={styles.printBtn} onClick={handlePrint}>
          הדפסת נתונים
        </button>
        <div className={styles.content} id="printableContent">
          <h2>נתוני הרכב</h2>
          <ul>
            {!!Object.keys(licenseData).length &&
              Object.keys(fieldNames).map(
                (i, idx) =>
                  licenseData[i] && (
                    <li key={idx}>
                      <p>{fieldNames[i]}:</p>
                      <p>
                        {fieldNames[i] === "שנת יצור"
                          ? removeLeadingZeros(licenseData.yop)
                          : fieldNames[i] === "טסט אחרון" ||
                            fieldNames[i] === "תוקף רישיון רכב" ||
                            fieldNames[i] === "מועד עלייה לכביש"
                          ? 
                          // formatDate(licenseData[i])
                          licenseData[i]
                          : 
                          removeLeadingZeros(licenseData[i])}
                      </p>
                    </li>
                  )
              )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LicenseModal;

import { get, post, put } from "../client";
import {
  ADD_SUPPLIER_MANUFACTURER,
  CREATE_REPLY,
  GET_SUPPLIER_MANUFACTURERS,
  MOVE_ALL_TO_ARCHIVE,
  MOVE_TO_ARCHIVE,
  SUPPLIER_REQUESTS,
} from "../routes";

export default {
  supplier_requests: async (page, size, isArchive, priceFilter) =>
  await get(SUPPLIER_REQUESTS(page, size, isArchive, priceFilter)),


  create_reply: async (data) => await post(CREATE_REPLY, data),

  move_to_archive: async (data) => await put(MOVE_TO_ARCHIVE, data),

  move_all_to_archive: async (data) => await put(MOVE_ALL_TO_ARCHIVE, data),

  get_supplier_manufacturers: async (vehicle) =>
    await get(GET_SUPPLIER_MANUFACTURERS(vehicle)),

  add_supplier_manufacturer: async (data) =>
    await post(ADD_SUPPLIER_MANUFACTURER, data),
};

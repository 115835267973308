import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../layouts/Layout";
import Spinner from "../components/Spinner";
import AUTH from "../api/services/AUTH";
import styles from "../styles/_pages/forgot.module.scss";
import { paths } from "../routes/paths";

const Forgot = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("")
    const [code, setCode] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false);

    const [emailError, setEmailError] = useState(null);
    const [codeError, setCodeError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);

    const [step, setStep] = useState(0);

    const handleVerify = async () => {
        const payload = {
            email,
        }

        setLoading(true)
        try {
            const res = await AUTH.forgot(payload)

            if (res.status === 200) {
                toast.success(res.data.message)
                setStep(1)
            }
        } catch (error) {
            //console.log(error)
            toast.error(error.response.data.message)
        }

        setLoading(false)
    }

    const handleSubmit = async () => {

        if (password.length < 6) {
            setPasswordError("על הסיסמה להכיל לפחות 6 תווים.")
        }

        if (password === 6 || password > 6) {

            setPasswordError(null)

            const payload = {
                email,
                code,
                password,
            }

            setLoading(true)
            try {
                const res = await AUTH.forgot_verify(payload)
                //console.log(res)

                if (res.status === 200) {
                    toast.success(res.data.message)
                    navigate(paths.login)
                }
            } catch (error) {
                //console.log(error)
                toast.error(error.response.data.message)
            }

            setLoading(false)
        }
    }

    return (
        <Layout>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <div className={styles.card}>
                        {
                            step === 0
                                ?
                                <h2>שליחת מייל שחזור</h2>
                                :
                                <h2>שחזור סיסמה</h2>
                        }

                        {
                            step === 0
                                ?
                                <p className={styles.text}>יש להכניס את כתובת המייל המשויכת לחשבונך</p>
                                :
                                <p className={styles.text}>אנא רשום את כתובת המייל והקוד שקיבלת, יש לבדוק גם בתיקיית הספאם שלך.</p>
                        }

                        <div className={styles.field}>
                            {/* <label>אימייל</label> */}
                            <input type="email" placeholder="אימייל" autoComplete="off" value={email} onChange={(e) => setEmail(e.target.value)} />
                            {emailError && <small className={styles.error}>{emailError}</small>}
                        </div>

                        {
                            step === 1
                            &&
                            <>
                                <div className={styles.field}>
                                    <label>קוד</label>
                                    <input type="number" placeholder="הקוד שנשלח אליך" name="code" value={code} onChange={(e) => setCode(e.target.value)} />
                                    {codeError && <small className={styles.error}>{codeError}</small>}
                                </div>

                                <div className={styles.field}>
                                    <label>סיסמה חדשה</label>
                                    <input type="password" placeholder="הזן סיסמה חדשה" autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    {passwordError && <small className={styles.error}>{passwordError}</small>}
                                </div>
                            </>
                        }

                        {
                            step === 0
                                ?
                                <button onClick={handleVerify}>{loading ? <Spinner /> : "שלח מייל שחזור"}</button>
                                :
                                <button onClick={handleSubmit}>{loading ? <Spinner /> : "אפס סיסמה"}</button>
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Forgot
import { toast } from "react-toastify";
import { paths } from "../routes/paths";

export const isTokenExpired = (status, navigate) => {
  if (status === 401) {
    toast.error("Session Expired. Please login.")
    localStorage.clear()
    navigate(paths.login);
  }
};

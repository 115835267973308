import StepCard from "../../features/main/StepCard";
import styles from "../../styles/_sections/main/steps.module.scss";

const Steps = ({ dynamicContent }) => {
  return (
    <section className={styles.container}>
      <img
        src="/assets/images/main_img_2.png"
        alt="graphic"
        className={styles.graphic}
      />
      <div className={styles.wrapper}>
        <StepCard
          icon="/assets/icons/search.svg"
          title={dynamicContent.featureCard1Title}
          text={dynamicContent.featureCard1Body}
          position="1"
        />

        <StepCard
          icon="/assets/icons/listing.svg"
          title={dynamicContent.featureCard2Title}
          text={dynamicContent.featureCard2Body}
          position="2"
        />

        <StepCard
          icon="/assets/icons/check.svg"
          title={dynamicContent.featureCard3Title}
          text={dynamicContent.featureCard3Body}
          position="3"
        />

        <StepCard
          icon="/assets/icons/truck_2.svg"
          title={dynamicContent.featureCard4Title}
          text={dynamicContent.featureCard4Body}
          position="4"
        />
      </div>
    </section>
  );
};

export default Steps;

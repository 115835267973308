import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import SUPPLIER from "../api/services/SUPPLIER";
import { isTokenExpired } from "../utils/isTokenExpired";
import { IMAGE_BASE_URL } from "../config";
import Layout from "../layouts/Layout";
import styles from "../styles/_pages/archive.module.scss";
import Loader from "../components/Loader";
import { paths } from "../routes/paths";
import { toast } from "react-toastify";

const SupplierArchive = () => {
  const navigate = useNavigate();

  const [requests, setRequests] = useState();

  const [requestsCopy, setRequestsCopy] = useState();

  const [removed, setRemoved] = useState(null);

  const [searchVal, setSearchVal] = useState("");

  const [priceFilter, setPriceFilter] = useState("ACCEPT_WITH_PRICE"); // default value

  let token = localStorage.getItem("authToken");

  let userType = localStorage.getItem("user_type");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const recordsPerPage = 30;
  const pageLimit = 10;

  const [lowerPageBound, setLowerPageBound] = useState(0);
  const [upperPageBound, setUpperPageBound] = useState(pageLimit);

  // REDIRECT TO LOGIN IF NO TOKEN
  useEffect(() => {
    if (!token) {
      navigate(paths.login);
    }
  }, [token]);

  function formatTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours}:${minutes.toString().padStart(2, "0")}`;
  }

  // IF THE USER IS NOT SUPPLIER THEN NAVIGATE HIM FROM THIS SCREEN
  useEffect(() => {
    if (JSON.parse(userType) !== "SUPPLIER") {
      navigate(paths.main);
    }
  }, [userType]);

  useEffect(() => {
    // //console.log(requestsCopy);
  }, [requestsCopy]);

  // FETCH REQUESTS
  useEffect(() => {
    setRemoved(null);
    const fetchRequests = async () => {
      try {
        const res = await SUPPLIER.supplier_requests(
          currentPage,
          recordsPerPage,
          true,
          priceFilter
        );
        if (res.result.length > 0) {
          const filteredResults = res.result.filter(
            (x) => x.isArchivedBySupplier
          );

          // Sorting the filtered results by updatedAt in descending order
          const sortedResults = res.result.sort((a, b) => {
            const dateA = a.lastReply?.updatedAt
              ? new Date(a.lastReply.updatedAt)
              : null;
            const dateB = b.lastReply?.updatedAt
              ? new Date(b.lastReply.updatedAt)
              : null;

            if (dateA === null && dateB === null) return 0;
            if (dateA === null) return 1;
            if (dateB === null) return -1;

            return dateB - dateA;
          });

          setRequests(sortedResults);
          setRequestsCopy(sortedResults);
          setTotalPages(res.totalPages);
        } else {
          setRequests([]);
          setRequestsCopy([]);
        }
      } catch (error) {
        //console.log(error);
        setRequests([]);
        setRequestsCopy([]);
        isTokenExpired(error.response.data.status, navigate);
      }
    };
    fetchRequests();
  }, [removed, currentPage, priceFilter]);

  // ============= PAGINATION CODE =============
  // Create an array from 1 to totalPages
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  // Pagination handlers
  const handlePaginationClick = (page) => setCurrentPage(page);

  const handleNextClick = () => {
    if (currentPage + 1 > upperPageBound) {
      setLowerPageBound(lowerPageBound + pageLimit);
      setUpperPageBound(upperPageBound + pageLimit);
    }
    if (currentPage + 1 <= totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevClick = () => {
    if ((currentPage - 1) % pageLimit === 0) {
      setLowerPageBound(lowerPageBound - pageLimit);
      setUpperPageBound(upperPageBound - pageLimit);
    }
    if (currentPage - 1 >= 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // ============= PAGINATION CODE END =============

  function userTypeHeb(param) {
    switch (param) {
      case "GARAGE":
        return "מוסך";
      case "PRIVATE":
        return "פרטי";
      case "SUPPLIER":
        return "ספק";
      case "APPRAISER":
        return "שמאי";
      case "LEASING_COMPANY":
        return "חברת ליסינג";
      case "INSURANCE_COMAPNY":
        return "חברת ביטוח";
    }
  }

  // REMOVE FROM ARCHIVE
  const removeFromArchive = async (id) => {
    const payload = { id, status: false };

    try {
      const res = await SUPPLIER.move_to_archive(payload);
      toast.success(res.data.message);
      setRemoved(true);
    } catch (error) {
      //console.log(error);
      toast.error(error.response.data.message);
    }
  };

  // HANDLE SEARCH
  const handleSearch = (e) => {
    setSearchVal(e.target.value);
    let search = e.target.value;
    if (search.trim()) {
      if (requests) {
        if (requests.length > 0) {
          let filtered = requests.filter(
            (i) =>
              i.subscriberId?.name.includes(search) ||
              i.customPartName.includes(search) ||
              i.part?.name.includes(search) ||
              i.carLicenseNumber.includes(search)
          );
          setRequestsCopy(filtered);
        }
      }
    } else {
      if (requests) {
        if (requests.length > 0) {
          setRequestsCopy(requests);
        }
      }
    }
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {/* CARD */}

          <h2>ארכיון בקשות</h2>
          {requestsCopy && <pre style={{ maxWidth: "400px" }}></pre>}
          {!requestsCopy && <Loader />}
          {requestsCopy && (
            <div className={styles.card}>
              <div className={styles.search}>
                <input
                  type="text"
                  value={searchVal}
                  onChange={handleSearch}
                  placeholder="חיפוש"
                />
                {/* <button onClick={handleSearch}>חפש</button> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // marginBottom: "30px",
                    gap: "10px",
                  }}
                >
                  {/* <span style={{ marginRight: "0" }}>סינון בקשות:</span> */}
                  <select
                    value={priceFilter}
                    onChange={(e) => setPriceFilter(e.target.value)}
                    style={{ width: "auto", maxWidth: "250px" }} // Adjust this value as needed
                  >
                    <option value="">כל הבקשות</option>
                    <option value="ACCEPT_WITH_PRICE">
                      {" "}
                      הצג בקשות עם מחיר בלבד
                    </option>
                  </select>
                </div>
              </div>

              {/* TABLE ----------- */}
              <div style={{ overflowX: "auto", width: "100%" }}>
                <table
                  className={styles.mainTable}
                  style={{ borderCollapse: "collapse" }}
                >
                  <thead>
                    <tr>
                      {/* <th style={{ width: "70px" }}></th> */}
                      {/* Client type */}
                      <th style={{ width: "180px", textAlign: "center" }}>
                        סוג לקוח
                      </th>
                      {/* Client Name */}
                      <th style={{ width: "180px", textAlign: "center" }}>
                        שם לקוח
                      </th>
                      <th style={{ width: "180px", textAlign: "center" }}>
                        עיר
                      </th>
                      <th style={{ width: "180px", textAlign: "center" }}>
                        טלפון
                      </th>
                      {/* Manufacturer */}
                      <th>יצרן</th>
                      {/* car model */}
                      {/* <th>דגם</th> */}
                      {/* Car license number */}
                      <th>מס' רכב</th>
                      {/* Part name */}
                      <th>חלק</th>
                      {/* Price */}
                      <th>מחיר</th>
                      {/* Comment */}
                      <th>הערה</th>
                      {/* search date  */}
                      <th>תאריך בקשה</th>
                      {/* reply date  */}
                      <th>תאריך תגובה</th>

                      {/* Year of car production */}
                      {/* <th>שנת יצור</th> */}
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {requestsCopy.map((i, idx) => (
                      <tr>
                        {/* <td>
                          <input
                            type="checkbox"
                            // checked={selectedRequests.find((x) => x._id === i._id)}
                            // onClick={() => handleCheckBox(i._id)}
                          />
                        </td> */}
                        {/* client type */}
                        <td style={{ border: "1px solid #a9a9a9" }}>
                          {userTypeHeb(i.subscriberId?.subscriberType)}
                        </td>
                        {/* client name */}
                        <td style={{ border: "1px solid #a9a9a9" }}>
                          {i.subscriberId?.name}
                        </td>
                        <td style={{ border: "1px solid #a9a9a9" }}>
                          {i.subscriberId?.city}
                        </td>
                        {/* client phone */}
                        <td style={{ border: "1px solid #a9a9a9" }}>
                          {i.subscriberId?.phone && (
                            <>
                              <a href={`tel:${i.subscriberId.phone}`}>
                                {i.subscriberId.phone}
                              </a>{" "}
                              <a
                                style={{ color: "green" }}
                                href={`https://api.whatsapp.com/send?phone=${i.subscriberId.phone}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <br />
                                {/* <img
          src="/assets/icons/whatsapp_2.svg" // Replace with the actual path to your WhatsApp icon
          alt="WhatsApp"
          width="20"
          height="20"
        /> */}
                                <span style={{ color: "green" }}>
                                  שליחת WhatsApp
                                </span>
                              </a>
                            </>
                          )}
                        </td>

                        {/* manufacturer */}
                        <td style={{ border: "1px solid #a9a9a9" }}>
                          {i.manufacturer} -{i.model ? i.model : "-"} שנת {""}
                          {i.yop ? i.yop : "-"}
                        </td>
                        {/* car license number */}
                        {/* car model */}
                        {/* <td style={{ border: "1px solid #a9a9a9" }}>
                          {" "}
                          
                        </td> */}
                        <td style={{ border: "1px solid #a9a9a9" }}>
                          {i.carLicenseNumber ? i.carLicenseNumber : "-"}
                        </td>
                        {/* part name */}
                        <td
                          className={styles.part}
                          style={{ border: "1px solid #a9a9a9" }}
                        >
                          {!i.customPartName ? (
                            <div className={styles.part}>
                              {i.images && i.images.length > 0
                                ? i.images.map((i) => (
                                    <img
                                      src={`${IMAGE_BASE_URL}${i}`}
                                      alt="part"
                                      // onClick={() =>
                                      //   setImagePopup(`${IMAGE_BASE_URL}${i}`)
                                      // }
                                    />
                                  ))
                                : null}
                              {!i.isVehicle && i.part && i.part.name
                                ? i.part.name
                                : "רכב שלם"}
                            </div>
                          ) : (
                            <div className={styles.part}>
                              {i.customPartName}
                            </div>
                          )}
                        </td>
                        <td style={{ border: "1px solid #a9a9a9" }}>
                          <p
                            style={{
                              maxWidth: "80px",

                              textAlign: "center",
                              backgroundColor: "#fff83f",
                            }}
                          >
                            {i?.lastReply?.price
                              ? `₪${new Intl.NumberFormat().format(
                                  i?.lastReply?.price
                                )}`
                              : "לא נשלח מחיר"}
                          </p>
                        </td>
                        <td style={{ border: "1px solid #a9a9a9" }}>
                          {i?.lastReply?.comment}
                        </td>
                        {/* search date */}
                        <td style={{ border: "1px solid #a9a9a9" }}>
                          {i.createdAt
                            ? format(new Date(i.createdAt), "dd.MM.yyyy")
                            : "-"}
                        </td>
                        {/* reply date */}
                        <td style={{ border: "1px solid #a9a9a9" }}>
                          {i?.lastReply?.updatedAt ? (
                            <>
                              {format(
                                new Date(i?.lastReply?.updatedAt),
                                "dd.MM.yyyy"
                              )}
                              - {formatTime(i?.lastReply?.updatedAt)}
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        {/* yop */}
                        {/* <td style={{ border: "1px solid #a9a9a9" }}>
                          {i.yop ? i.yop : "-"}
                        </td> */}
                        <td
                          style={{
                            cursor: "pointer",
                            border: "1px solid #a9a9a9",
                          }}
                        >
                          <button
                            style={{
                              backgroundColor: "blue",
                              color: "white",
                              border: "none",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              height: "50px",
                            }}
                            onClick={() => removeFromArchive(i._id)}
                          >
                            החזר לבקשות שלי
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/* MOBILE */}
          <div className={styles.cardsWrapper}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: "30px",
                gap: "10px",
              }}
            >
              <span style={{ marginRight: "0" }}>סינון בקשות:</span>
              <select
                value={priceFilter}
                onChange={(e) => setPriceFilter(e.target.value)}
                style={{
                  width: "200px",
                  height: "40px",
                  fontFamily: "Open Sans",
                  fontSize: "16px",
                }} // Adjust this value as needed
              >
                <option value="">כל הבקשות</option>
                <option value="ACCEPT_WITH_PRICE">בקשות עם מחיר בלבד</option>
              </select>
            </div>
            {/* CARDS */}
            {requestsCopy && (
              <div className={styles.cards}>
                {requestsCopy.map((i, idx) => (
                  <>
                    <div className={`${styles.reqCard}`}>
                      <div className={styles.item}>
                        <h3>יצרן</h3>
                        <p>{i.manufacturer}</p>
                      </div>
                      <div className={styles.item}>
                        <h3>מס' רכב</h3>
                        <p>{i.carLicenseNumber ? i.carLicenseNumber : "-"}</p>
                      </div>
                      <div className={styles.item}>
                        <h3>חלק</h3>
                        <p>
                          {!i.customPartName
                            ? !i.isVehicle && i.part && i.part.name
                              ? i.part.name
                              : "רכב שלם"
                            : i.customPartName}
                        </p>
                      </div>
                      <div className={styles.item}>
                        <h3>מחיר</h3>
                        <p
                          style={{
                            maxWidth: "80px",
                            backgroundColor: "#fff83f",
                          }}
                        >
                          {i?.lastReply?.price
                            ? `₪${new Intl.NumberFormat().format(
                                i?.lastReply?.price
                              )}`
                            : "לא נשלח מחיר"}
                        </p>
                      </div>
                      <div className={styles.item}>
                        <h3>הערה</h3>
                        <p>
                          {i?.lastReply?.comment ? i?.lastReply?.comment : "-"}
                        </p>
                      </div>
                      <div className={styles.item}>
                        <h3>תאריך בקשה</h3>
                        <p>{format(new Date(i.createdAt), "dd.MM.yyyy")}</p>
                      </div>
                      <div className={styles.item}>
                        <h3>תאריך תגובה</h3>
                        <p>
                          {i?.lastReply?.updatedAt
                            ? format(
                                new Date(i?.lastReply?.updatedAt),
                                "dd.MM.yyyy"
                              )
                            : "-"}
                          {"\n"}
                          {i?.lastReply?.updatedAt
                            ? formatTime(i?.lastReply?.updatedAt)
                            : "-"}
                        </p>
                      </div>
                      <div className={styles.item}>
                        <h3>דגם</h3>
                        <p>{i.model ? i.model : "-"}</p>
                      </div>
                      <div className={styles.item}>
                        <h3>שנת יצור</h3>
                        <p>{i.yop ? i.yop : "-"}</p>
                      </div>
                      <div className={styles.item}>
                        <h3>סוג לקוח</h3>
                        {userTypeHeb(i.subscriberId?.subscriberType)}
                      </div>
                      {/* Add the phone and WhatsApp links here */}
                      {i.subscriberId?.phone && (
                        <div className={styles.item}>
                          <h3>טלפון</h3>
                          <p>
                            <a href={`tel:${i.subscriberId.phone}`}>
                              {i.subscriberId.phone}
                            </a>{" "}
                            <a
                              style={{ color: "green" }}
                              href={`https://api.whatsapp.com/send?phone=${i.subscriberId.phone}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <br />
                              <span style={{ color: "green" }}> WhatsApp</span>
                            </a>
                          </p>
                        </div>
                      )}
                      <div className={styles.item}>
                        {/* <img
                          src="/assets/icons/trash.svg"
                          alt="delete"
                          onClick={() => removeFromArchive(i._id)}
                        /> */}
                        <button
                          style={{
                            backgroundColor: "blue",
                            color: "white",
                            border: "none",
                            padding: "5px 10px",
                            borderRadius: "5px",
                          }}
                          onClick={() => removeFromArchive(i._id)}
                        >
                          הוצא מארכיון
                        </button>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>

          {/* ----------- PAGINATION ----------- */}
          <div className={styles.pagination}>
            <button disabled={currentPage === 1} onClick={handlePrevClick}>
              הקודם
            </button>
            <ul>
              {pages.slice(lowerPageBound, upperPageBound).map((page) => (
                <li
                  key={page}
                  style={page === currentPage ? { fontWeight: "bold" } : null}
                  className={`${page === currentPage ? styles.active : ""}`}
                  onClick={() => handlePaginationClick(page)}
                >
                  {page}
                </li>
              ))}
            </ul>
            <button
              disabled={currentPage === totalPages}
              onClick={handleNextClick}
            >
              הבא
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SupplierArchive;

import { useEffect, useState } from "react";
import CATEGORIES from "../../api/services/CATEGORIES";
import PARTS from "../../api/services/PARTS";
import styles from "../../styles/_features/createRequest/accordion.module.scss";

const Accordion = ({
  categories,
  setCategories,
  category,
  setCategory,
  vehicle,
  setParts,
  duplicateArr,
  setDuplicateArr,
  selectedParts,
  setSelectedParts,
}) => {
  // for accordion
  const [isOpen, setIsOpen] = useState(false);

  // for parts
  const [selectedImages, setSelectedImages] = useState([]);

  // ========= FETCH CATEGORIES =========
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await CATEGORIES.get();
        if (res.result.length > 0) {
          let filtered = [];

          filtered = res.result.filter((j) => j.vehicle === vehicle);

          if (filtered.length > 0) {
            setCategories(filtered);
            setCategory(filtered[0]._id)
          } else {
            setCategories([]);
          }
        } else {
          setCategories([]);
        }
      } catch (error) {
        //console.log(error);
        setCategories([]);
      }
    };

    fetchCategories();
  }, [vehicle]);

  // ============  PARTS ==============
  useEffect(() => {
    if (category) {
      const fetchParts = async () => {
        try {
          const res = await PARTS.get(category);
          if (res.result.length > 0) {
            // create a copy of original array with additional properties
            let arr = res.result.map((i) => {
              let obj = {
                _id: i._id,
                name: i.name,
                desc: "",
                img: null,
                imgPreview: null,
              };
              return obj;
            });
            setDuplicateArr(arr);
            setParts(res.result);
          }
        } catch (error) {
          //console.log(error);
          setParts([]);
          setDuplicateArr([]);
        }
      };

      fetchParts();
    }
  }, [category]);

  // handle description change
  const handleDescChange = (event, index) => {
    let temp = duplicateArr;
    temp[index] = {
      ...temp[index],
      desc: event.target.value,
    };
    setDuplicateArr(temp);
  };

  // handle image change
  const handleImgChange = (event, index) => {
    let temp = duplicateArr;
    temp[index] = {
      ...temp[index],
      img: event.target.files[0],
      imgPreview: URL.createObjectURL(event.target.files[0]),
    };
    setDuplicateArr(temp);

    setSelectedImages((prev) => [
      ...prev,
      {
        idx: index,
        img: URL.createObjectURL(event.target.files[0]),
      },
    ]);
  };

  // handle checkbox change
  const handleCheckChange = (id, index) => {
    if (selectedParts.find((j) => j._id === id)) {
      setSelectedParts(selectedParts.filter((x) => x._id !== id));
    } else {
      setSelectedParts((prev) => {
        return [...prev, duplicateArr[index]];
      });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={() => setIsOpen(!isOpen)}>
        <p>לצפיה בכל הקטגוריות והחלקים</p>
      </div>

      <div
        className={`${styles.body} ${
          isOpen ? styles.openBody : styles.closeBody
        }`}
      >
        {/* ========= CATEGORIES ========= */}
        <div className={styles.catContainer}>
          <h2>
            <img src="/assets/icons/categories.svg" alt="categories" />
            קטגוריות חלפים
          </h2>

          <div className={styles.categories}>
            {categories &&
              categories.map((i) => (
                <div
                  className={`${category === i._id ? styles.active : ""} ${
                    styles.category
                  }`}
                  key={i._id}
                  onClick={() => setCategory(i._id)}
                >
                  <span>{">"}</span>
                  <p>{i.name}</p>
                </div>
              ))}
          </div>
        </div>

        {/* ========= PARTS ========= */}
        <div className={styles.partsContainer}>
          <div style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <th>בחירת חלק</th>
                  <th>הערות לספק</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {duplicateArr &&
                  duplicateArr.map((i, idx) => (
                    <tr key={i._id}>
                      <td>
                        <div className={styles.name}>
                          <input
                            type="checkbox"
                            name="partName"
                            className={styles.checkbox}
                            checked={selectedParts.find((j) => j._id === i._id)}
                            onChange={(e) => handleCheckChange(i._id, idx)}
                          />
                          <p>{i.name}</p>
                        </div>
                      </td>
                      <td>
                        <input
                          type="text"
                          name={i._id}
                          className={styles.desc}
                          value={i._desc}
                          onChange={(e) => handleDescChange(e, idx)}
                        />
                      </td>
                      {selectedImages.length > 0 &&
                      selectedImages.find((x) => x.idx === idx) ? (
                        <td>
                          <img
                            src={selectedImages.find((x) => x.idx === idx).img}
                            alt={i.name}
                            className={styles.imgPreview}
                          />
                        </td>
                      ) : (
                        <td>
                          <input
                            type="file"
                            name="partImg"
                            className={styles.imgInput}
                            onChange={(e) => handleImgChange(e, idx)}
                          />
                          <img src="/assets/icons/camera.svg" alt="camera" />
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;

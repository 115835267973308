import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import styles from "../styles/_layouts/sidenav.module.scss";

const style = {
  height: "100vh",
  width: "100%",
  position: "fixed",
  left: "0px",
  top: "0px",
  opacity: 0.45,
  background: "#000000 0% 0% no-repeat padding-box",
};

const Backdrop = ({ setIsOpen }) => (
  <div style={style} onClick={() => setIsOpen(false)}></div>
);

const SideNav = ({ setSideNav }) => {
  const navigate = useNavigate();

  let token = localStorage.getItem("authToken");
  token = JSON.parse(token);

  let userType = localStorage.getItem("user_type");
  userType = JSON.parse(userType);

  return (
    <div className={styles.mainContainer}>
      <Backdrop setIsOpen={setSideNav} />

      <div className={styles.container}>
        <img
          src="/assets/icons/logo_8.svg"
          alt="logo"
          className={styles.logo}
          style={{ width: "100%" }}
          onClick={() => navigate(paths.main)}
        />

        {/* RIGHT */}
        <div>
          {/* NAV */}
          {/* if user is not logged in */}
          {!token && (
            <nav>
              <ul>
                <li onClick={() => navigate(paths.main)}>ראשי</li>
                {/* <li>השירותים שלנו</li> */}
                <li onClick={() => navigate(paths.contact)}>צרו קשר</li>
              </ul>
            </nav>
          )}

          {/* if user is a subscriber */}
          {token && userType === "SUBSCRIBER" && (
            <nav>
              <ul>
                <li>
                  {/* <div
                    style={{ width: "100px", height: "100px" }}
                    id="google_translate_element"
                  ></div> */}
                </li>
                <li onClick={() => navigate(paths.main)}>ראשי</li>
                <li onClick={() => navigate(paths.createRequest)}>חיפוש חלק</li>
                <li onClick={() => navigate(paths.subscriberHistory)}>
                  החלקים שלי
                </li>
                <li onClick={() => navigate(paths.subscriberArchive)}>
                  ארכיון בקשות
                </li>
                <li onClick={() => navigate(paths.contact)}>צרו קשר</li>
                {/* <li>השירותים שלנו</li> */}
              </ul>
            </nav>
          )}

          {/* if user is a supplier */}
          {token && userType === "SUPPLIER" && (
            <nav>
              <ul>
              <li onClick={() => navigate(paths.supplierHistory)}>
                      בקשות החלקים שלי
                    </li>
                    <li onClick={() => navigate(paths.supplierArchive)}>
                      ארכיון בקשות חלקים
                    </li>
                    <li onClick={() => navigate(paths.createRequest)}>
                      חיפוש חלק
                      <span style={{backgroundColor:'red', padding:'0 6px', borderRadius:'5px', color:'white', marginRight:'6px'}}>חדש</span>
                    </li>
                    <li onClick={() => navigate(paths.subscriberHistory)}>
                      החיפושים שלי
                    </li>

                 
                    <li onClick={() => navigate(paths.supplierManufacturers)}>
                      בחירת יצרנים
                    </li>

                    <li onClick={() => navigate(paths.contact)}>צרו קשר</li>
              </ul>
            </nav>
          )}
        </div>

        <div>
          {!token && (
            <div className={styles.btns}>
              <button onClick={() => navigate(paths.login)}>
                <img src="/assets/icons/user_square.svg" alt="login" />
                התחברות למערכת
              </button>
              <button onClick={() => navigate(paths.register)}>
                <img src="/assets/icons/doc.svg" alt="signup" />
                הרשמה למערכת
              </button>
            </div>
          )}
        </div>
        <div
          style={{
            marginTop: "35px",
            borderTopWidth: "1px",
            borderTopColor: "#ededed",
            borderTopStyle: "solid",
          }}
        >
          <h3
            style={{
              textAlign: "center",
              padding: "10px",
              fontSize: "16px",
            }}
          >
            הורידו את האפליקציה שלנו לחוויה מקסימלית!
          </h3>
          <div
            style={{
              marginTop: "5px",
            }}
          >
            <a href="https://apps.apple.com/il/app/get-part/id6443868231">
              <img src="/assets/images/AppStore.svg" alt="appstore" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.gettparts.app">
              <img src="/assets/images/GooglePlay.svg" alt="google_play" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;

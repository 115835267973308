import styles from "../../styles/_features/main/aboutCard.module.scss";

const AboutCard = ({ dynamicContent }) => {
  return (
    <div className={styles.container}>
              <h3 style={{
                textAlign:'center',
                padding:'10px',
                fontSize:'20px'
              }}>הורידו את האפליקציה שלנו לחוויה מקסימלית!</h3>
        <div className={styles.btns}>
            <a href="https://apps.apple.com/il/app/get-part/id6443868231"><img src="/assets/images/AppStore.svg" alt="appstore" /></a>
            <a href="https://play.google.com/store/apps/details?id=com.gettparts.app"><img src="/assets/images/GooglePlay.svg" alt="google_play" /></a>
          </div>
      <div className={styles.text}>
        <h2>{dynamicContent.aboutCardTitle}</h2>
        <p>{dynamicContent.aboutCardDescription}</p>
      </div>
      <img src="/assets/images/main_img_1.png" alt="graphic" />
    </div>
  );
};

export default AboutCard;

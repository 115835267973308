import styles from "../styles/_components/spinner.module.scss";

const Spinner = () => (
  <img
    src="/assets/icons/spinner.png"
    alt="spinner"
    className={styles.spinner}
  />
);

export default Spinner;

//  PREVIOUS
//  export const BASE_URL = "https://api2.get-part.co.il:9072/api/v1";
//  PREVIOUS
//  export const IMAGE_BASE_URL = "https://api2.get-part.co.il:9072";

// BASE_URL
export const BASE_URL = "https://api.get-part.co.il:9072/api/v1";

// IMAGE_BASE_URL
export const IMAGE_BASE_URL = "https://api.get-part.co.il:9072";

// export const BASE_URL = "http://3.6.204.35:9072/api/v1";
// export const IMAGE_BASE_URL = "http://3.6.204.35:9072";
import styles from "../styles/_components/loader.module.scss";

const Loader = () => {
  return (
    <div className={styles.outer}>
      <div className={styles.middle}>
        <div className={styles.inner}></div>
      </div>
    </div>
  );
};

export default Loader;

import  React,{ useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CONTENT from "../api/services/CONTENT";
import DemolishModal from "../components/DemolishModal";
import Loader from "../components/Loader";
import CarouselCard from "../features/main/CarouselCard";
import Layout from "../layouts/Layout";
import { paths } from "../routes/paths";
import Carousel from "../sections/_main/Carousel";
import Hero from "../sections/_main/Hero";
import Steps from "../sections/_main/Steps";
import styles from "../styles/_pages/main.module.scss";

const Main = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let userType = localStorage.getItem("user_type");

  const [dynamicContent, setDynamicContent] = useState(null);

  // IF THE USER IS SUPPLIER THEN NAVIGATE HIM FROM THIS SCREEN
  useEffect(() => {
    if (JSON.parse(userType) === "SUPPLIER") {
      navigate(paths.supplierHistory);
    }
  }, [userType]);

  const [demolishModal, setDemolishModal] = useState();

  // FETCH DYNAMIC CONTENT
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await CONTENT.get_content();
        setDynamicContent(res.result);
      } catch (error) {
        //console.log(error);
      }
    };

    fetchData();
  }, []);

  if (!dynamicContent) {
    return (
      <Layout>
        <div className={styles.main}>
          <Loader />
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <Hero setIsOpen={setDemolishModal} dynamicContent={dynamicContent} />
      <Steps dynamicContent={dynamicContent} />
      {/* <Carousel /> */}

      {demolishModal && <DemolishModal setIsOpen={setDemolishModal} />}
    </Layout>
  );
};

export default Main;

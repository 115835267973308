import { useState } from "react";
import { useEffect } from "react";
import USER from "../api/services/USER";
import styles from "../styles/_components/userDetailsModal.module.scss";
import Loader from "./Loader";
import { useForm } from "react-hook-form";
import { put } from "../api/client";
const inputStyle = {
  inputText: {
    width: "225px",
    height: "45px",
    background: "#ffffff 0% 0% no-repeat padding-box",
    border: "1px solid #95a5b2",
    borderRadius: "5px",
    font: "normal normal normal 15px/20px 'Open Sans', sans-serif",
    letterSpacing: "0px",
    color: "#000000",
    marginBottom: "18px",
    paddingRight: "15px",
    paddingLeft: "15px",
  },
};
const style = {
  height: "100vh",
  width: "100%",
  position: "fixed",
  left: "0px",
  top: "0px",
  opacity: 0.45,
  background: "#000000 0% 0% no-repeat padding-box",
};
export const userTypeHeb = (userType) => {
  switch (userType) {
    case "SUPPLIER":
      return "ספק";
    case "Subscriber":
      return "מנוי";

    default:
      break;
  }
};
const Backdrop = ({ setIsOpen }) => (
  <div style={style} onClick={() => setIsOpen(false)}></div>
);

const UserDetailsModal = ({ setIsOpen }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [details, setDetails] = useState();
  const [fetching, setFetching] = useState(false);
  const [message, setMessage] = useState({
    status: "PENNDING",
    display: false,
    message: "",
  });
  const [toggle, setToggle] = useState(true); // toggle for edit mode
  const onSubmit = (data) => {
    //console.log(data);
    setFetching(true);

    const updateProfile = async () => {
      try {
        const updatedData = await put("customer/updateProfile", data);
        setFetching(false);
        //console.log(updatedData);
        setMessage({
          status: "UPDATED",
          display: true,
          message: "הפרופיל עודכן בהצלחה",
        });
        setTimeout(() => {
          setMessage({ status: "PENNDING", display: false, message: "" });
        }, 3000);
        return true;
      } catch (error) {
        //console.log(error);
        setMessage({
          status: "ERROR",
          display: true,
          message: "אירעה שגיאה בעדכון הפרופיל",
        });
        setTimeout(() => {
          setMessage({ status: "PENNDING", display: false, message: "" });
        }, 3000);
        setFetching(false);
        return false;
      }
    };
    const updated = updateProfile();
  };
  //console.log(watch("example")); // watch input value by passing the name of it

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await USER.get();
        setDetails(res.result);
      } catch (error) {
        //console.log(error);
      }
    };

    fetchData();
  }, []);

  if (!details) {
    return (
      <div className={styles.mainContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.mainContainer}>
      <Backdrop setIsOpen={setIsOpen} />
      <div className={styles.container}>
        <span className={styles.close} onClick={() => setIsOpen(false)}>
          x
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            style={{
              background: "#2654b8",
              padding: "0.5rem",
              color: "white",
              fontSize: "1rem",
              border: "none",
              borderRadius: "5px",
            }}
            onClick={() => setToggle(!toggle)}
          >
            {toggle ? "עריכת פרטי משתמש" : "צפייה בפרטי משתמש"}
          </button>
        </div>
        <div className={styles.content}>
          <h4>פרטי משתמש</h4>
          {details.userType !== "Supplier" && (
            <>
              <div>
                <label style={{ fontWeight: "700" }}>
                  חבילה עדכנית במערכת:
                </label>
                {/* <p>חודשי / חיפוש חד פעמי / לא קיימת חבילה</p> */}
                <p>{details.lastSubscriptionPlan?.name}</p>
              </div>
              <br />
              <div>
                <label style={{ fontWeight: "700" }}>סוג לקוח:</label>
                <p>{userTypeHeb(details.userType)}</p>
              </div>
            </>
          )}
          <hr style={{ marginTop: "1rem" }} />
          {message && message.display !== true && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {!fetching ? (
                <div className={styles.myForm}>
                  <div>
                    <label>אימייל:</label>
                    <br />
                    <>
                      <input
                        // {...register("email")}
                        style={{
                          ...inputStyle.inputText,
                          border: toggle ? "none" : "1px solid #95a5b2",
                        }}
                        type="email"
                        id="email"
                        name="email"
                        disabled={toggle}
                        defaultValue={details.email}
                      />
                    </>
                  </div>
                  <div>
                    <label>כתובת:</label>
                    <>
                      <br />
                      <input
                        {...register("address")}
                        style={{
                          ...inputStyle.inputText,
                          border: toggle ? "none" : "1px solid #95a5b2",
                        }}
                        type="address"
                        id="address"
                        name="address"
                        disabled={toggle}
                        defaultValue={details.address}
                      />
                    </>
                  </div>

                  <div>
                    <label>עיר:</label>
                    <>
                      <br />
                      <input
                        {...register("city")}
                        style={{
                          ...inputStyle.inputText,
                          border: toggle ? "none" : "1px solid #95a5b2",
                        }}
                        type="city"
                        id="city"
                        name="city"
                        disabled={toggle}
                        defaultValue={details.city}
                      />
                    </>
                  </div>

                  <div>
                    <label>טלפון:</label>

                    <>
                      <br />
                      <input
                        style={{
                          ...inputStyle.inputText,
                          border: toggle ? "none" : "1px solid #95a5b2",
                        }}
                        {...register("phone")}
                        type="phone"
                        id="phone"
                        name="phone"
                        disabled={true}
                        defaultValue={details.phone}
                      />
                    </>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
              {!toggle && (
                <button
                  disabled={fetching}
                  style={{
                    minWidth: "100px",
                    width: "auto",
                    margin: "0 auto",
                    background: "#2654b8",
                    padding: "0.5rem",
                    color: "white",
                    fontSize: "1rem",
                    border: "none",
                    borderRadius: "5px",
                    display: "grid",
                  }}
                  type="submit"
                >
                  {fetching ? "שולח" : "שמור"}
                </button>
              )}
            </form>
          )}
          {message && message.display === true && (
            <div className={styles.message}>
              <p>{message.message}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserDetailsModal;

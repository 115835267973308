import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import { SearchContext } from "../App";
import styles from "../styles/_layouts/header.module.scss";
import { get } from "../api/client";
import MANUFACTURERS from "../api/services/MANUFACTURERS";

const Header = ({ setIsOpen, setSideNav }) => {
  const navigate = useNavigate();
  const [isVerified, setIsverifed] = useState(null);

  useEffect(() => {
    const checkVerify = async () => {
      const res = await get(
        "https://api.get-part.co.il:9072/api/v1/customer/profile"
      );
      // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
      if (res.status === 200) {
        const {
          result: { isEmailVerified },
        } = res;
        setIsverifed(isEmailVerified);
      }
    };
    checkVerify();
  }, []);

  let token = localStorage.getItem("authToken");
  token = JSON.parse(token);

  let userType = localStorage.getItem("user_type");

  userType = JSON.parse(userType);

  // LOG OUT
  const handleLogout = () => {
    localStorage.clear();
    navigate(paths.login);
  };

  const {
    vehicleVal,
    manufacturerVal,
    modelVal,
    subModelVal,
    yopVal,
    gearTypeVal,
    engineTypeVal,
    hpVal,
    licenceNumber,
    disableAllField,
    manuList,
    modelsList,
    collapse,
    licenseAPIData,
  } = useContext(SearchContext);

  const [vehicle, setVehicle] = vehicleVal;
  const [manufacturer, setManufacturer] = manufacturerVal;
  const [model, setModel] = modelVal;
  const [subModel, setSubModel] = subModelVal;
  const [yop, setYop] = yopVal;
  const [gearType, setGearType] = gearTypeVal;
  const [engineType, setEngineType] = engineTypeVal;
  const [hp, setHp] = hpVal;
  const [licenceNum, setLicenceNum] = licenceNumber;
  const [disableAll, setDisableAll] = disableAllField;
  const [manufacturers, setManufacturers] = manuList;
  const [models, setModels] = modelsList;
  const [collapseSections, setCollapseSections] = collapse;
  const [licenseData, setLicenseData] = licenseAPIData;

  useEffect(() => {
    // console.log(token, userType, "token");
  }, [token]);

  const handleLogoClick = async () => {
    setLicenceNum("");
    setHp("");
    setEngineType("");
    setGearType("");
    setLicenceNum("");
    setCollapseSections("");
    setLicenseData("");
    setManufacturer("");
    setModel("");
    setSubModel("");
    setYop("");
    setVehicle("CAR");

    navigate(paths.main);
  };

  return (
    <>
      {isVerified === false && (
        <div
          style={{
            backgroundColor: "#f44336",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "sticky",
            top: 0,
            zIndex: 99,
          }}
        >
          <span
            style={{
              color: "#FFFFFF",
              fontSize: "18px",
              textAlign: "center",
              padding: "10px",
            }}
          >
            כדי להתחיל להשתמש במערכת אנא אמתו את חשבונכם{" "}
            <a
              href={paths.otp}
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                color: "unset",
              }}
            >
              לחצו כאן{" "}
            </a>
          </span>
        </div>
      )}
      <div className="row-content">
        <header className={styles.header}>
          <div className={styles.wrapper}>
            {/* RIGHT */}
            <div className={styles.right}>
              <img
                src="/assets/icons/logo_8.svg"
                alt="logo"
                className={styles.logo}
                onClick={handleLogoClick}
              />

              {/* NAV */}
              {/* if user is not logged in */}
              {!token && (
                <nav>
                  <ul>
                    <li onClick={() => navigate(paths.main)}>ראשי</li>
                    {/* <li>השירותים שלנו</li> */}
                    <li onClick={() => navigate(paths.contact)}>צרו קשר</li>
                  </ul>
                </nav>
              )}

              {/* if user is a subscriber */}
              {token && userType === "SUBSCRIBER" && (
                <nav>
                  <ul>
                    <li onClick={() => navigate(paths.main)}>ראשי</li>
                    <li onClick={() => navigate(paths.createRequest)}>
                      חיפוש חלק
                    </li>
                    <li onClick={() => navigate(paths.subscriberHistory)}>
                      החיפושים שלי
                    </li>
                    <li onClick={() => navigate(paths.subscriberArchive)}>
                      ארכיון חיפושים
                    </li>
                    <li onClick={() => navigate(paths.contact)}>צרו קשר</li>
                    {/* <li>השירותים שלנו</li> */}
                    {/* <li onClick={() => navigate(paths.latestSubscriberRequests)}>
                    החיפושים שלי
                  </li> */}
                  </ul>
                </nav>
              )}

              {/* if user is a supplier */}
              {token && userType === "SUPPLIER" && (
                <nav>
                  <ul>
                  
                    <li onClick={() => navigate(paths.supplierHistory)}>
                      בקשות החלקים שלי
                    </li>
                    <li onClick={() => navigate(paths.supplierArchive)}>
                      ארכיון בקשות חלקים
                    </li>
                    <li onClick={() => navigate(paths.createRequest)}>
                      חיפוש חלק
                    <span style={{backgroundColor:'red', padding:'0 6px', borderRadius:'5px', color:'white', marginRight:'6px'}}>חדש</span>
                    </li>
                    <li onClick={() => navigate(paths.subscriberHistory)}>
                      החיפושים שלי
                    </li>

                 
                    <li onClick={() => navigate(paths.supplierManufacturers)}>
                      בחירת יצרנים
                    </li>

                    <li onClick={() => navigate(paths.contact)}>צרו קשר</li>
                    {/* <li>השירותים שלנו</li> */}
                    {/* <li onClick={() => navigate(paths.latestSupplierRequests)}>
                    החיפושים שלי
                  </li> */}
                  </ul>
                </nav>
              )}
            </div>

            {/* LEFT */}
            <div className={styles.left}>
              {token ? (
                <>
                  <img
                    src="/assets/icons/menu.svg"
                    alt="menu"
                    className={styles.menu}
                    onClick={() => setSideNav(true)}
                  />

                  <img
                    src="/assets/icons/user.svg"
                    alt="user"
                    className={styles.userIcon}
                    onClick={() => setIsOpen(true)}
                  />
                  <p className={styles.logout} onClick={handleLogout}>
                    {"להתנתק"}
                  </p>
                </>
              ) : (
                <div className={styles.btns}>
                  <button onClick={() => navigate(paths.login)}>
                    <img src="/assets/icons/user_square.svg" alt="login" />
                    התחברות למערכת
                  </button>
                  <button onClick={() => navigate(paths.register)}>
                    <img src="/assets/icons/doc.svg" alt="signup" />
                    הרשמה למערכת
                  </button>
                  <img
                    src="/assets/icons/menu.svg"
                    alt="menu"
                    className={styles.menu}
                    onClick={() => setSideNav(true)}
                  />
                </div>
              )}
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;

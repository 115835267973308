// AUTH
const LOGIN = `/customer/signIn`;

const REGISTER = `/customer/signUp`;

const FORGOT_PASSWORD = `/customer/forgotPassword`;

const FORGOT_VERIFY = `/customer/forgotPasswordCode`;

const MANUFACTURERS = (vehicle) => `/admin/manufacturers?vehicle=${vehicle}`;

const CATEGORIES = `/admin/categories`;

const PARTS = (id) => `/admin/parts/${id}`;

// SUBSCRIBERS
const CREATE_REQUEST = `/customer/prerequest`;

const CREATE_SEARCH_ENTRY = `/customer/request`;

const SUBSCRIBER_REQUESTS = `/customer/requests`;

const ALL_PLANS = `/customer/plans`;

const SUBSCRIBE = `/customer/subscribe`;

const ALL_PARTS = `/admin/allParts`;

const MOVE_TO_ARCHIVE_SUB = `/customer/request/archive`;

// SUPPLIER
const SUPPLIER_REQUESTS = (page, size, isArchive, priceFilter) =>
  `/customer/supplierRequests?page=${page}&size=${size}&archived=${isArchive}&priceFilter=${priceFilter}`;


const CREATE_REPLY = `/customer/acceptRequest`;

const MOVE_TO_ARCHIVE = `/customer/supplierRequest/archive`;

const MOVE_ALL_TO_ARCHIVE = `/customer/supplierRequest/archiveMany`;

const GET_SUPPLIER_MANUFACTURERS = (vehicle) =>
  `/customer/supplierManufacturers?vehicle=${vehicle}`;

const ADD_SUPPLIER_MANUFACTURER = `/customer/assignAndUpdateManufacturers`;

// USER
const USER_STATS = `/customer/profile`;

// CONTACT
const CONTACT = `/customer/contactUs`;

// CONTENT
const CONTENT = `/admin/dynamicContent`;

// OTHERS
const ALL_COUNTRIES = `/all`;

export {
  LOGIN,
  REGISTER,
  FORGOT_VERIFY,
  FORGOT_PASSWORD,
  MANUFACTURERS,
  CATEGORIES,
  PARTS,
  CREATE_REQUEST,
  CREATE_SEARCH_ENTRY,
  SUBSCRIBER_REQUESTS,
  ALL_PARTS,
  SUPPLIER_REQUESTS,
  CREATE_REPLY,
  MOVE_TO_ARCHIVE,
  MOVE_TO_ARCHIVE_SUB,
  MOVE_ALL_TO_ARCHIVE,
  ALL_PLANS,
  SUBSCRIBE,
  USER_STATS,
  CONTACT,
  CONTENT,
  GET_SUPPLIER_MANUFACTURERS,
  ADD_SUPPLIER_MANUFACTURER,
  ALL_COUNTRIES,
};

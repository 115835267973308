import React, { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import AppRoutes from "./routes/routes";
import ReactPixel from 'react-facebook-pixel';



export const SearchContext = createContext();
export const SocketContext = createContext();

let token = localStorage.getItem("authToken");

// sockets
// const socket = io("http://54.167.55.58:9072", {
//   query: `token=${JSON.parse(token)}`,
// });

const socket = io("https://api.get-part.co.il:9072/", {
  query: `token=${JSON.parse(token)}`,
});
 
const App = () => {
   // Facebook Pixel initialization
   useEffect(() => {
    const advancedMatching = {}; 
    const options = {
        autoConfig: true,
        debug: false,
    };
    ReactPixel.init('6313909531988166', advancedMatching, options); // Add your Facebook Pixel ID here
    ReactPixel.pageView();
  }, []);
  
  // SOCKET CONNECTION ==
  useEffect(() => {
    // if (socket) {
    socket.on("connectUser", (skt) => {
      console.log(skt, "connectUser");
    });
    socket.on("connect", (skt) => {
      socket.emit("connectUser", skt);
    });

    socket.on("connect_error", (skt) => {
      console.log(skt, "Socket Error");
    });

    socket.on("disconnect", () => {
      console.log("disconnect");
    });
    // }

    return () => {
      socket.connected && socket.disconnect();
      socket.off("connectUser");
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);
  // SOCKET CONNECTION END ==
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [vehicle, setVehicle] = useState("CAR");
  const [manufacturer, setManufacturer] = useState("");
  const [model, setModel] = useState("");
  const [subModel, setSubModel] = useState("");
  const [yop, setYop] = useState("");
  const [gearType, setGearType] = useState("");
  const [engineType, setEngineType] = useState("");
  const [hp, setHp] = useState("");
  const [licenceNum, setLicenceNum] = useState("");
  const [disableAll, setDisableAll] = useState(false);
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [collapseSections, setCollapseSections] = useState(false);
  const [collapsePartsSection, setCollapsePartsSection] = useState(false);
  const [selectedPartsCopy, setSelectedPartsCopy] = useState(false);
  const [licenseData, setLicenseData] = useState({});

  return (
    <SocketContext.Provider value={socket}>
      <SearchContext.Provider
        value={{
          vehicleVal: [vehicle, setVehicle],
          manufacturerVal: [manufacturer, setManufacturer],
          modelVal: [model, setModel],
          subModelVal: [subModel, setSubModel],
          yopVal: [yop, setYop],
          gearTypeVal: [gearType, setGearType],
          engineTypeVal: [engineType, setEngineType],
          hpVal: [hp, setHp],
          licenceNumber: [licenceNum, setLicenceNum],
          disableAllField: [disableAll, setDisableAll],
          manuList: [manufacturers, setManufacturers],
          modelsList: [models, setModels],
          collapse: [collapseSections, setCollapseSections],
          collapseParts: [collapsePartsSection, setCollapsePartsSection],
          selParts: [selectedPartsCopy, setSelectedPartsCopy],
          licenseAPIData: [licenseData, setLicenseData],
        }}
      >
        <AppRoutes />
      </SearchContext.Provider>
    </SocketContext.Provider>
  );
};

export default App;

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Contact from "../pages/Contact";
import CreateRequest from "../pages/CreateRequest";
import Login from "../pages/Login";
import Main from "../pages/Main";
import SubscriberRequests from "../pages/SubscriberRequests";
import Register from "../pages/Register";
import { paths } from "./paths";
import SupplierRequests from "../pages/SupplierRequests";
import SupplierArchive from "../pages/SupplierArchive";
import Regulations from "../pages/Regulations";
import Forgot from "../pages/Forgot";
import Otp from "../pages/Otp";
import LatestSupplierRequests from "../pages/LatestSupplierRequests";
import SubscriberArchive from "../pages/SubscriberArchive";
import LatestSubscriberRequests from "../pages/LatestSubscriberRequests";
import SupplierManufacturers from "../pages/SupplierManufacturers";

const AppRoutes = () => {
  const token = localStorage.getItem("authToken");

  return (
    <Router>
      <Routes>
        <Route
          exact
          path="/"
          element={
            !token ? (
              <Navigate to={paths.main} replace />
            ) : (
              <Navigate to={paths.main} replace />
            )
          }
        />

        <Route exact path={paths.login} element={<Login />} />
        <Route exact path={paths.register} element={<Register />} />
        <Route exact path={paths.forgot} element={<Forgot />} />
        <Route exact path={paths.otp} element={<Otp />} />

        <Route exact path={paths.main} element={<Main />} />
        <Route
          exact
          path={paths.subscriberHistory}
          element={<SubscriberRequests />}
        />

        <Route
          exact
          path={paths.subscriberArchive}
          element={<SubscriberArchive />}
        />

        <Route
          exact
          path={paths.latestSubscriberRequests}
          element={<LatestSubscriberRequests />}
        />

        <Route exact path={paths.createRequest} element={<CreateRequest />} />

        <Route exact path={paths.contact} element={<Contact />} />

        <Route
          exact
          path={paths.supplierHistory}
          element={<SupplierRequests />}
        />

        <Route
          exact
          path={paths.supplierArchive}
          element={<SupplierArchive />}
        />

        <Route
          exact
          path={paths.latestSupplierRequests}
          element={<LatestSupplierRequests />}
        />

        <Route
          exact
          path={paths.supplierManufacturers}
          element={<SupplierManufacturers />}
        />

        <Route exact path={paths.regulations} element={<Regulations />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;

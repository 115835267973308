import { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import styles from "../styles/_pages/supplierManufacturers.module.scss";
import MANUFACTURERS from "../api/services/MANUFACTURERS";
import Loader from "../components/Loader";
import Spinner from "../components/Spinner";
import SUPPLIER from "../api/services/SUPPLIER";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";

const SupplierManufacturers = () => {
  const navigate = useNavigate();

  let token = localStorage.getItem("authToken");

  let userType = localStorage.getItem("user_type");

  // REDIRECT TO LOGIN IF NO TOKEN
  useEffect(() => {
    if (!token) {
      navigate(paths.login);
    }
  }, [token]);

  // IF THE USER IS NOT SUPPLIER THEN NAVIGATE HIM FROM THIS SCREEN
  useEffect(() => {
    if (JSON.parse(userType) !== "SUPPLIER") {
      navigate(paths.main);
    }
  }, [userType]);

  const [vehicle, setVehicle] = useState("CAR");
  const [loading, setLoading] = useState(false);
  const [manufacturers, setManufacturers] = useState();
  const [supplierManufacturers, setSupplierManufacturers] = useState([]);
  const [apiResponse, setApiResponse] = useState(false);

  // FETCH MANUFACTURERS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await MANUFACTURERS.get(vehicle);
        setManufacturers(res.result.manufacturers);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [vehicle]);

  // FETCH SUPPLIER MANUFACTURERS
  useEffect(() => {
    setApiResponse(false);
    const fetchData = async () => {
      try {
        const res = await SUPPLIER.get_supplier_manufacturers(vehicle);
        setSupplierManufacturers(res.result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [vehicle, apiResponse]);

  // ADD MANUFACTURER
  const handleClick = async () => {
    const payload = {
      vehicle: vehicle,
      manufacturerIds: supplierManufacturers.map((i) => i.manufacturerId._id),
    };

    setLoading(true);
    try {
      const res = await SUPPLIER.add_supplier_manufacturer(payload);
      toast.success(res.data.message);
      setApiResponse(true);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
    setLoading(false);
  };

  if (!manufacturers) {
    return (
      <Layout>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <h2>בחירה ושיוך יצרנים</h2>
            <Loader />
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <h2>בחירה ושיוך יצרנים</h2>

          <div className={styles.vehicles}>
            <button
              className={`${vehicle === "CAR" ? styles.active : ""} ${
                styles.tab
              }`}
              onClick={() => setVehicle("CAR")}
            >
              <img src="/assets/icons/car.svg" alt="car" />
              רכב
            </button>
            <button
              className={`${vehicle === "TRUCK" ? styles.active : ""} ${
                styles.tab
              } `}
              onClick={() => setVehicle("TRUCK")}
            >
              <img src="/assets/icons/truck.svg" alt="truck" />
              משאית
            </button>
            <button
              className={`${vehicle === "BIKE" ? styles.active : ""} ${
                styles.tab
              } `}
              onClick={() => setVehicle("BIKE")}
            >
              <img src="/assets/icons/bike.svg" alt="bike" />
              אופנוע
            </button>
          </div>

          <div className={styles.card}>
            {/* ASSIGN ALL */}
            <div
              className={styles.assignAll}
              onClick={() => {
                if (supplierManufacturers.length === manufacturers.length) {
                  setSupplierManufacturers([]);
                } else {
                  setSupplierManufacturers(
                    manufacturers.map((i) => {
                      return {
                        manufacturerId: {
                          _id: i.id,
                        },
                      };
                    })
                  );
                }
              }}
            >
              <input
                type="checkbox"
                checked={supplierManufacturers.length === manufacturers.length}
              />
              <p>שיוך כל היצרנים</p>
            </div>

            {/* MANUFACTURERS */}
            <ul className={styles.manufacturers}>
              {manufacturers?.map((i) => (
                <li
                  key={i.id}
                  onClick={() => {
                    // if manufacturer exists then remove
                    if (
                      supplierManufacturers.some(
                        (x) => x.manufacturerId._id === i.id
                      )
                    ) {
                      setSupplierManufacturers(
                        supplierManufacturers.filter(
                          (j) => j.manufacturerId._id !== i.id
                        )
                      );
                    }
                    // else add it
                    else {
                      setSupplierManufacturers((prev) => [
                        ...prev,
                        { manufacturerId: { _id: i.id } },
                      ]);
                    }
                  }}
                >
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={supplierManufacturers.some(
                      (x) => x.manufacturerId._id === i.id
                    )}
                  />
                  <p>{i.name}</p>
                </li>
              ))}
            </ul>

            <button type="submit" onClick={handleClick}>
              {loading ? <Spinner /> : "שיוך יצרן"}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SupplierManufacturers;

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AUTH from "../api/services/AUTH";
import Modal from "../components/Modal";
import Spinner from "../components/Spinner";
import Layout from "../layouts/Layout";
import { paths } from "../routes/paths";
import styles from "../styles/_pages/register.module.scss";
import { SearchContext } from "../App";
import axios from "axios";
import { BASE_URL } from "../config";
import { isTokenExpired } from "../utils/isTokenExpired";
import SUBSCRIBER from "../api/services/SUBSCRIBER";

const Register = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const token = localStorage.getItem("authToken");

  // states
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [additionalPhone, setAdditionalPhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [type, setType] = useState("PRIVATE");

  // errors
  const [nameError, setNameError] = useState(null);
  const [usernameeError, setUsernameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");
  const [terms, setTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [loading, setLoading] = useState(false);

  // global state
  const {
    vehicleVal,
    manufacturerVal,
    modelVal,
    subModelVal,
    yopVal,
    gearTypeVal,
    engineTypeVal,
    hpVal,
    licenceNumber,
    disableAllField,
    manuList,
    modelsList,
    selParts,
  } = useContext(SearchContext);

  const [vehicle, setVehicle] = vehicleVal;
  const [manufacturer, setManufacturer] = manufacturerVal;
  const [model, setModel] = modelVal;
  const [subModel, setSubModel] = subModelVal;
  const [yop, setYop] = yopVal;
  const [gearType, setGearType] = gearTypeVal;
  const [engineType, setEngineType] = engineTypeVal;
  const [hp, setHp] = hpVal;
  const [licenceNum, setLicenceNum] = licenceNumber;
  const [disableAll, setDisableAll] = disableAllField;
  const [manufacturers, setManufacturers] = manuList;
  const [models, setModels] = modelsList;
  const [selectedPartsCopy, setSelectedPartsCopy] = selParts;

  // if token exists, redirect to dash
  useEffect(() => {
    if (token && type !== "SUPPLIER") {
      navigate(paths.main);
    }
  }, [token]);

  // submit form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const israeliPhoneNumberRegex =
      /^(?:\+972|0)(?:\-)?(?:5[0-9]|[2-4]|[8-9])\d{7}$/;
    console.log("clicked", "clicked");
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!terms) {
      setTermsError("יש לאשר תנאי שימוש");
    }
    if (!name) {
      setNameError("שדה חובה");
    }
    if (!username) {
      setUsernameError("שדה חובה");
    }
    if (!password) {
      setPasswordError("שדה חובה");
    }
    if (!phone) {
      setPhoneError("שדה חובה");
    }

    // if (phone && israeliPhoneNumberRegex.test(phone) === false) {
    //   console.log(
    //     israeliPhoneNumberRegex.test(phone),
    //     "israeliPhoneNumberRegex.test(phone)"
    //   );
    //   setPhoneError("טלפון לא תקין");
    // }
    if (!email) {
      setEmailError("שדה חובה");
    }
    if (email && emailRegex.test(email) === false) {
      setEmailError("כתובת אימייל לא תקין");
    }
    if (!address) {
      setAddressError("שדה חובה");
    }
    if (!city) {
      setCityError("שדה חובה");
    }
    // if (!postalCode) {
    //   setPostalCodeError("Postal Code is required");
    // }

    if (
      name &&
      username &&
      password &&
      phone &&
      // phone &&
      // israeliPhoneNumberRegex.test(phone) &&
      email &&
      emailRegex.test(email) &&
      address &&
      city &&
      terms
      // &&
      // postalCode
    ) {
      let payload = {
        name,
        email,
        phone,
        password,
        username,
        address,
        city,
        // zipCode: postalCode,
        userType: type === "SUPPLIER" ? "SUPPLIER" : "SUBSCRIBER",
      };

      if (additionalPhone && type === "SUPPLIER") {
        payload.additionalPhone = additionalPhone;
      }

      if (type !== "SUPPLIER") {
        payload.subscriberType = type;
      }

      setLoading(true);
      try {
        const data = await AUTH.register(payload);

        let token = JSON.stringify(data.data.result.authToken);
        if (type !== "SUPPLIER") {
          localStorage.setItem("authToken", token);
          let user_id = JSON.stringify(data.data.result._id);
          localStorage.setItem("user_id", user_id);
          localStorage.setItem("user_type", JSON.stringify("SUBSCRIBER"));

          toast.success(data.data.message);

          if (localStorage.getItem("isCartClicked")) {
            // IF THERE ARE NO PARTS SELECTED THEN REDIRECT TO MAIN PAGE
            if (!selectedPartsCopy) {
              localStorage.removeItem("isCartClicked");
              navigate(paths.main);
            }

            // IF THE USER HAS ALREADY SELECTED THE PARTS THEN
            else {
              const selectedParts = selectedPartsCopy;

              // CREATE REQUEST ===========
              const fun = (part_id, part_img, part_desc, part_name, custom) => {
                let formdata = new FormData();

                formdata.append("subscriberId", JSON.parse(user_id));

                if (custom) {
                  formdata.append("customPartName", part_name);
                } else {
                  formdata.append("part", part_id);
                }

                if (part_img) {
                  formdata.append("images", part_img);
                }

                if (part_desc) {
                  formdata.append("description", part_desc);
                }

                if (manufacturer) {
                  formdata.append("manufacturer", manufacturer);
                }

                if (model) {
                  formdata.append("model", model);
                }

                if (subModel) {
                  formdata.append("subModel", subModel);
                }

                if (yop) {
                  formdata.append("yop", yop);
                }

                if (gearType) {
                  formdata.append("gearType", gearType);
                }

                if (engineType) {
                  formdata.append("engineType", engineType);
                }

                if (hp) {
                  formdata.append("hp", hp);
                }
                if (licenceNumber) {
                  formdata.append("carLicenseNumber", licenceNumber[0]);
                }

                return new Promise((resolve) => {
                  const config = {
                    headers: {
                      Authorization: token && JSON.parse(token),
                    },
                  };

                  axios
                    .post(`${BASE_URL}/customer/prerequest`, formdata, config)
                    .then((res) => {
                      console.log(res);
                      resolve(res.data.result.requestId);
                    })
                    .catch((err) => {
                      isTokenExpired(err.response.data.status, navigate);
                    });
                });
              };

              let responses = [];

              const callMe = async () => {
                let promises = selectedParts.map((i) => {
                  return fun(i._id, i.img, i.desc, i.name, i.custom);
                });

                responses = await Promise.all(promises);
                // console.log(responses);

                if (responses) {
                  // call search API here!
                  const entryPayload = {
                    requestIds: responses,
                  };

                  try {
                    const res = await SUBSCRIBER.create_search_entry(
                      entryPayload
                    );
                    if (res.status === 200) {
                      toast.success("Request Created.");
                      navigate(paths.subscriberHistory);
                      localStorage.removeItem("isCartClicked");

                      // if (profileRes.result.isEmailVerified) {
                      //   navigate(paths.createRequest);
                      // } else {
                      //   navigate(paths.main);
                      // }
                    }
                  } catch (error) {
                    console.log(error);
                    isTokenExpired(error.response.data.status, navigate);
                    toast.error(error.response.data.message);
                    localStorage.removeItem("isCartClicked");
                    setIsOpen(true);
                  }
                }
              };

              callMe();

              // CREATE REQUEST END =======
            }
          }
          // IF THE USERS DOES NOT COME FROM THE SEARCH PAGE
          else {
            navigate(paths.main);
          }
        }
        if (type === "SUPPLIER") {
          localStorage.setItem("user_type", JSON.stringify("SUPPLIER"));

          navigate(paths.login);
        }
      } catch (error) {
        toast.error(error.response.data.message);
        console.log(error);
      }
      setLoading(false);
    }
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.card}>
            <h3>הרשמה</h3>
            <p className={styles.text1}>
              ברוכים הבאים , מלאו את הפרטים הבאים להרשמה לאתר כדי לקבל את כל
              השירותים שלנו
            </p>

            <form onSubmit={handleSubmit}>
              <div className={styles.row}>
                <div className={styles.field}>
                  <label>סוג עסק</label>
                  <div className={styles.radioRow}>
                    <div className={styles.radio}>
                      <input
                        type="radio"
                        value="PRIVATE"
                        name="PRIVATE"
                        checked={type === "PRIVATE"}
                        onChange={(e) => setType(e.target.value)}
                      />
                      פרטי
                    </div>
                    <div className={styles.radio}>
                      <input
                        type="radio"
                        value="GARAGE"
                        name="GARAGE"
                        checked={type === "GARAGE"}
                        onChange={(e) => setType(e.target.value)}
                      />
                      מוסך
                    </div>
                    <div className={styles.radio}>
                      <input
                        type="radio"
                        value="APPRAISER"
                        name="APPRAISER"
                        checked={type === "APPRAISER"}
                        onChange={(e) => setType(e.target.value)}
                      />
                      שמאי
                    </div>
                    <div className={styles.radio}>
                      <input
                        type="radio"
                        value="LEASING_COMPANY"
                        name="LEASING_COMPANY"
                        checked={type === "LEASING_COMPANY"}
                        onChange={(e) => setType(e.target.value)}
                      />
                      חברת ליסיג
                    </div>
                    <div className={styles.radio}>
                      <input
                        type="radio"
                        value="INSURANCE_COMPANY"
                        name="INSURANCE_COMPANY"
                        checked={type === "INSURANCE_COMPANY"}
                        onChange={(e) => setType(e.target.value)}
                      />
                      פחח
                    </div>
                    <div className={styles.radio}>
                      <input
                        type="radio"
                        value="SUPPLIER"
                        name="SUPPLIER"
                        checked={type === "SUPPLIER"}
                        onChange={(e) => setType(e.target.value)}
                      />
                      ספק
                    </div>
                  </div>
                </div>
              </div>

              <div className={`${styles.formFields}`}>
                {/* NAME */}
                <div className={styles.field}>
                  <label>שם הלקוח / עסק</label>
                  <input
                    type="text"
                    // placeholder="ישראל ישראלי"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {nameError && (
                    <small className={styles.error}>{nameError}</small>
                  )}
                </div>

                {/* USERNAME */}
                <div className={styles.field}>
                  <label>
                    שם משתמש{" "}
                    <span style={{ fontSize: "12px" }}>
                      (באנגלית בלבד ללא רווחים){" "}
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder=""
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  {usernameeError && (
                    <small className={styles.error}>{usernameeError}</small>
                  )}
                </div>

                {/* PASSWORD */}
                <div className={styles.field}>
                  <label>סיסמה</label>
                  <input
                    style={{ width: "100%" }}
                    type="password"
                    placeholder=""
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordError && (
                    <small className={styles.error}>{passwordError}</small>
                  )}
                </div>

                {/* PHONE */}
                <div className={styles.field}>
                  <label>טלפון</label>
                  <input
                    type="text"
                    // placeholder="ישראל ישראלי"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                  {phoneError && (
                    <small className={styles.error}>{phoneError}</small>
                  )}
                </div>

                {/* ADDITIONAL PHONE */}
                {type === "SUPPLIER" && (
                  <div className={styles.field}>
                    <label>טלפון נוסף (לא חובה)</label>
                    <input
                      type="text"
                      // placeholder="ישראל ישראלי"
                      name="additionalPhone"
                      value={additionalPhone}
                      onChange={(e) => setAdditionalPhone(e.target.value)}
                    />
                  </div>
                )}

                {/* EMAIL */}
                <div className={styles.field}>
                  <label>אימייל</label>
                  <input
                    type="text"
                    placeholder=""
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && (
                    <small className={styles.error}>{emailError}</small>
                  )}
                </div>

                {/* ADDRESS */}
                <div className={styles.field}>
                  <label>רחוב</label>
                  <input
                    type="text"
                    // placeholder="ישראל ישראלי"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  {addressError && (
                    <small className={styles.error}>{addressError}</small>
                  )}
                </div>

                {/* CITY */}
                <div className={styles.field}>
                  <label>עיר</label>
                  <input
                    type="text"
                    name="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  {cityError && (
                    <small className={styles.error}>{cityError}</small>
                  )}
                </div>
                {/* <div className={styles.field}>
                  <label>מיקוד</label>
                  <input
                    type="text"
                    placeholder=""
                    name="postalCode"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                  {postalCodeError && (
                    <small className={styles.error}>{postalCodeError}</small>
                  )}
                </div> */}
                <div className={styles.field} />
              </div>

              <p className={styles.text2}>
                לאחר הרישום יש למלא את חלקי הרכב המבוקשים
              </p>

              <div style={{ display: "grid", gap: "0" }}>
                <div
                  style={{
                    display: "flex",
                    margin: "0 auto",
                    justifyContent: "center",
                  }}
                >
                  <input
                    onClick={() => {
                      setTerms(!terms);
                    }}
                    style={{ margin: "0 0.5rem" }}
                    value={terms}
                    type="checkbox"
                    name="terms"
                  />{" "}
                  <p className={styles.text3}>
                    אני מצהיר שקראתי את{" "}
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(paths.regulations)}
                    >
                      תנאי השימוש
                    </span>
                  </p>
                </div>

                {termsError && (
                  <small
                    style={{ textAlign: "center", color: "red" }}
                    className={styles.error}
                  >
                    {termsError}
                  </small>
                )}
              </div>
              <br />
              <button type="submit">{loading ? <Spinner /> : "הרשמה"}</button>
            </form>
          </div>
        </div>
      </div>
      {isOpen && <Modal setIsOpen={setIsOpen} />}
    </Layout>
  );
};

export default Register;

import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Layout from "../layouts/Layout";
import Details from "../features/createRequest/Details";
import Categories from "../features/createRequest/Categories";
import Parts from "../features/createRequest/Parts";
import Cart from "../features/createRequest/Cart";
import Modal from "../components/Modal";
import Accordion from "../features/createRequest/Accordion";
import { SearchContext } from "../App";
import { BASE_URL } from "../config";
import { paths } from "../routes/paths";
import SUBSCRIBER from "../api/services/SUBSCRIBER";
import Spinner from "../components/Spinner";
import { isTokenExpired } from "../utils/isTokenExpired";
import styles from "../styles/_pages/createRequest.module.scss";

const CreateRequest = () => {
  const navigate = useNavigate();

  let token = localStorage.getItem("authToken");
  let user_id = localStorage.getItem("user_id");
  let userType = localStorage.getItem("user_type");

  const {
    vehicleVal,
    manufacturerVal,
    modelVal,
    subModelVal,
    yopVal,
    gearTypeVal,
    engineTypeVal,
    hpVal,
    licenceNumber,
    disableAllField,
    manuList,
    modelsList,
    collapse,
    collapseParts,
    selParts,
    licenseAPIData,
  } = useContext(SearchContext);

  const [vehicle, setVehicle] = vehicleVal;
  const [manufacturer, setManufacturer] = manufacturerVal;
  const [model, setModel] = modelVal;
  const [subModel, setSubModel] = subModelVal;
  const [yop, setYop] = yopVal;
  const [gearType, setGearType] = gearTypeVal;
  const [engineType, setEngineType] = engineTypeVal;
  const [hp, setHp] = hpVal;
  const [licenceNum, setLicenceNum] = licenceNumber;
  const [disableAll, setDisableAll] = disableAllField;
  const [manufacturers, setManufacturers] = manuList;
  const [models, setModels] = modelsList;
  const [collapseSections, setCollapseSections] = collapse;
  const [collapsePartsSection, setCollapsePartsSection] = collapseParts;
  const [selectedPartsCopy, setSelectedPartsCopy] = selParts;
  const [licenseData, setLicenseData] = licenseAPIData;

  const [vehicleNo, setVehicleNo] = useState("");
  const [carLicenceNo, setCarLicenceNo] = useState("");
  const [categories, setCategories] = useState("");
  const [category, setCategory] = useState("");
  const [parts, setParts] = useState("");
  const [duplicateArr, setDuplicateArr] = useState();
  const [selectedParts, setSelectedParts] = useState([]);
  const [part, setPart] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const validateForm = () => {
      if (!hp || !gearType || !yop || !engineType) {
        setIsFormValid(false);
      } else {
        setIsFormValid(true);
      }
    };

    validateForm();
  }, [hp, gearType, yop, engineType]);

  const createRequest = () => {
    setLoading(true);
    const fun = (part_id, part_img, part_desc, part_name, custom) => {
      let formdata = new FormData();
      if (custom) {
        formdata.append("customPartName", part_name);
      } else {
        formdata.append("part", part_id);
      }

      if (part_img) {
        formdata.append("images", part_img);
      }

      if (part_desc) {
        formdata.append("description", part_desc);
      }

      if (manufacturer) {
        formdata.append("manufacturer", manufacturer);
      }

      if (model) {
        formdata.append("model", model);
      }

      if (subModel) {
        formdata.append("subModel", subModel);
      }

      if (yop) {
        formdata.append("yop", yop);
      }

      if (gearType) {
        formdata.append("gearType", gearType);
      }

      if (engineType) {
        formdata.append("engineType", engineType);
      }

      if (hp) {
        formdata.append("hp", hp);
      }

      if (vehicleNo) {
        formdata.append("vehicleNumber", vehicleNo);
      }
      if (licenceNumber[0]) {
        formdata.append("carLicenseNumber", licenceNumber[0]);
      }
      if (!licenceNumber[0]) {
        formdata.append("carLicenseNumber", "000000");
      }

      return new Promise((resolve) => {
        const config = {
          headers: {
            Authorization: token && JSON.parse(token),
          },
        };

        axios
          .post(`${BASE_URL}/customer/prerequest`, formdata, config)
          .then((res) => {
            resolve(res.data.result.requestId);
          })
          .catch((err) => {
            isTokenExpired(err.response.data.status, navigate);
          });
      });
    };

    let responses = [];

    const callMe = async () => {
      let promises = selectedParts.map((i) => {
        return fun(i._id, i.img, i.desc, i.name, i.custom);
      });

      responses = await Promise.all(promises);

      if (responses) {
        const entryPayload = {
          requestIds: responses,
        };
        try {
          const res = await SUBSCRIBER.create_search_entry(entryPayload);
          if (res.status === 200) {
            toast.success("Request Created.");
            if (JSON.parse(userType) === "SUBSCRIBER") {
              navigate(paths.subscriberHistory);
            } else {
              navigate(paths.supplierHistory);
            }
          }
        } catch (error) {
          toast.error(error.response.data.message);
          setIsModalOpen(true);
        }
        setLoading(false);
      }
    };

    if (token) {
      callMe();
    } else {
      localStorage.setItem("isCartClicked", true);
      setSelectedPartsCopy(selectedParts);
      navigate(paths.login);
    }
  };

  return (
    <Layout>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <Details
            manufacturers={manufacturers}
            setManufacturers={setManufacturers}
            models={models}
            setModels={setModels}
            vehicle={vehicle}
            setVehicle={setVehicle}
            manufacturer={manufacturer}
            setManufacturer={setManufacturer}
            model={model}
            setModel={setModel}
            subModel={subModel}
            setSubModel={setSubModel}
            yop={yop}
            setYop={setYop}
            gearType={gearType}
            setGearType={setGearType}
            engineType={engineType}
            setEngineType={setEngineType}
            hp={hp}
            setHp={setHp}
            vehicleNo={vehicleNo}
            setVehicleNo={setVehicleNo}
            carLicenceNo={carLicenceNo}
            setCarLicenceNo={setCarLicenceNo}
            setDuplicateArr={setDuplicateArr}
            selectedParts={selectedParts}
            setSelectedParts={setSelectedParts}
            setCollapseSections={setCollapseSections}
            licenceNum={licenceNum}
            setLicenceNum={setLicenceNum}
            disableAll={disableAll}
            setDisableAll={setDisableAll}
            licenseData={licenseData}
            setLicenseData={setLicenseData}
          />

          <Categories
            categories={categories}
            setCategories={setCategories}
            category={category}
            setCategory={setCategory}
            setParts={setParts}
            vehicle={vehicle}
            setCollapsePartsSection={setCollapsePartsSection}
          />

          <div className={styles.rowWrap}>
            <Parts
              category={category}
              parts={parts}
              setParts={setParts}
              duplicateArr={duplicateArr}
              setDuplicateArr={setDuplicateArr}
              part={part}
              setPart={setPart}
              selectedParts={selectedParts}
              setSelectedParts={setSelectedParts}
            />

            {selectedParts.length > 0 && (
              <Cart
                selectedParts={selectedParts}
                setSelectedParts={setSelectedParts}
                handleSubmit={createRequest}
                loading={loading}
                disabled={selectedParts.length <= 0}
                isFormValid={isFormValid} // Pass isFormValid here
              />
            )}

            <Accordion
              categories={categories}
              setCategories={setCategories}
              category={category}
              setCategory={setCategory}
              vehicle={vehicle}
              setCollapsePartsSection={setCollapsePartsSection}
              parts={parts}
              setParts={setParts}
              duplicateArr={duplicateArr}
              setDuplicateArr={setDuplicateArr}
              part={part}
              setPart={setPart}
              selectedParts={selectedParts}
              setSelectedParts={setSelectedParts}
            />
          </div>
        </div>
      </div>

      {isModalOpen && <Modal setIsOpen={setIsModalOpen} />}
    </Layout>
  );
};

export default CreateRequest;
